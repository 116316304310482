import React, { useEffect } from "react";
import styles from "./Sidenav.module.css";
import PartnerLogoOrEmpty from "../shared/logos/PartnerLogoOrEmpty";
import {
  AdminPanelSettingsOutlined,
  ChevronLeft,
  ChevronRight,
  InsightsOutlined,
} from "@mui/icons-material";
import NavItem from "./NavItem";
import classNames from "classnames";
import UserActionsMenu from "../shared/UserActionsMenu";
import { useAuth0 } from "@auth0/auth0-react";
import { UserPartnerQuery } from "../../graphql/generated/graphql";
import { useAnalytics } from "@btr-energy/analytics";
import { NavLink, useLocation } from "react-router-dom";
import { createTabTitle } from "../../utils/createTabTitle";
import { bottomNavTabs, topNavTabs } from "./TabProperties";
import { useRoles } from "../../App/RolesContext";

export default function Sidenav({
  open,
  toggleOpen,
  partner,
  togglePortal,
  toggleChatBtr,
}: {
  open: boolean;
  toggleOpen: () => void;
  partner: UserPartnerQuery["currentUserPartner"];
  togglePortal: (() => void) | undefined;
  toggleChatBtr: (() => void) | undefined;
}) {
  const { user: currentUser } = useAuth0();
  const { pathname } = useLocation();
  const analytics = useAnalytics();
  const name = currentUser?.name || "Name not found";
  const nameFont = name.trim().length > 15 ? "12px" : "14px";

  const { roles } = useRoles();

  useEffect(() => {
    const allTabs = topNavTabs.concat(bottomNavTabs);

    const tabName =
      allTabs.find((tab) => tab.route === pathname)?.label ||
      allTabs
        .flatMap((tab) => tab.subItems)
        .find((tab) => tab?.route === pathname)?.label;

    if (tabName) document.title = createTabTitle(tabName);
  }, [pathname]);

  return (
    <div
      className={classNames(styles.sidenav, {
        [styles.closed]: !open,
      })}
    >
      <button
        className={styles.navToggle}
        onClick={() => {
          analytics.track({ name: "Sidenav toggled" });
          toggleOpen();
        }}
      >
        {open ? <ChevronLeft /> : <ChevronRight />}
      </button>

      <div className={styles.topNav}>
        <div className={styles.navHeader}>
          <NavLink to="/" className={styles.headerLink}>
            <img
              src="/logo-full.png"
              alt="BTR Energy"
              className={styles.btrLogo}
            />
          </NavLink>
        </div>

        <div className={styles.navTabs}>
          {topNavTabs.map((item) => (
            <NavItem
              key={item.label}
              collapsed={!open}
              enabled={item.allowedRoles.some((r) => roles.includes(r))}
              navItem={item}
            />
          ))}
        </div>
      </div>

      <div className={styles.bottomNav}>
        <div className={styles.navTabs}>
          {bottomNavTabs.map((item) => (
            <NavItem
              key={item.label}
              collapsed={!open}
              enabled={item.allowedRoles.some((r) => roles.includes(r))}
              navItem={item}
            />
          ))}

          {toggleChatBtr && (
            <NavLink
              to={"/"}
              onClick={toggleChatBtr}
              className={styles.toggleButtons}
            >
              <InsightsOutlined /> ChatBTR
            </NavLink>
          )}

          {togglePortal && (
            <NavLink
              to={"/"}
              onClick={togglePortal}
              className={styles.toggleButtons}
            >
              <AdminPanelSettingsOutlined /> Legacy Admin View
            </NavLink>
          )}
        </div>

        <hr className={styles.bottomNavHr} />

        <div className={styles.userDisplay}>
          <div className={styles.userInfo}>
            <PartnerLogoOrEmpty logoUrl={partner.logoUrl} />

            <div>
              <div style={{ fontSize: nameFont }} className={styles.userName}>
                {currentUser?.name}
              </div>
              <div className={styles.userPartner}>
                {partner.shortName} Portal
              </div>
            </div>
          </div>

          <UserActionsMenu />
        </div>
      </div>
    </div>
  );
}
