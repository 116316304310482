import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from "@mui/material";
import Export from "./Export";
import Confirm from "./Confirm";
import { ApolloQueryResult } from "@apollo/client";
import {
  RegistrationDashboardQuery,
  SubmissionFlow,
  SubmissionStep,
  UnsubmittedBatch,
} from "../../../../graphql/generated/graphql";

export default function RegistrationSubmissionFlow({
  refetch,
  submissionFlow,
  unsubmittedBatches,
  oemUnsubmitted,
}: {
  submissionFlow: SubmissionFlow;
  unsubmittedBatches: UnsubmittedBatch[];
  oemUnsubmitted: RegistrationDashboardQuery["unsubmittedCarbRegistrationsPerOem"];
  refetch: () => Promise<ApolloQueryResult<RegistrationDashboardQuery>>;
}) {
  return (
    <Card>
      <CardHeader title="Submit New CARB Registrations" />
      <CardContent>
        {steps({
          activeStep: resolveActiveStep(submissionFlow),
          refetch,
          unsubmittedBatches,
          oemUnsubmitted,
        })}
      </CardContent>
    </Card>
  );
}

function resolveActiveStep(submissionFlow: SubmissionFlow): number {
  switch (submissionFlow.step) {
    case SubmissionStep.Export:
      return 0;
    case SubmissionStep.Confirm:
      return 1;
  }
}

function steps({
  activeStep,
  refetch,
  unsubmittedBatches,
  oemUnsubmitted,
}: {
  activeStep: number;
  unsubmittedBatches: UnsubmittedBatch[];
  oemUnsubmitted: RegistrationDashboardQuery["unsubmittedCarbRegistrationsPerOem"];
  refetch: () => Promise<ApolloQueryResult<RegistrationDashboardQuery>>;
}) {
  const steps = [
    {
      label: "Export registrations",
      component: (
        <Export oemUnsubmitted={oemUnsubmitted} postExport={refetch} />
      ),
    },
    {
      label: "Confirm submission to CARB",
      component: (
        <Confirm unsubmittedBatches={unsubmittedBatches} refetch={refetch} />
      ),
    },
  ];

  return (
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      style={{ padding: 24 }}
    >
      {steps.map(({ label, component }, index) => (
        <Step key={label}>
          {activeStep === index ? (
            <StepLabel data-testid="active-step">{label}</StepLabel>
          ) : (
            <StepLabel>{label}</StepLabel>
          )}
          <StepContent>{component}</StepContent>
        </Step>
      ))}
    </Stepper>
  );
}
