import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { DeleteOutlined, ExpandMore } from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import { useDeleteModalContext } from "../../../../shared/components/modals/DeleteModal";
import styles from "./UserList.module.css";
import {
  partnerRoleLabels,
  readableUserRole,
} from "../../../../utils/partnerUserRoles";
import OptionMenu from "../../../../shared/components/OptionMenu";
import classNames from "classnames";
import { Tooltip } from "@mui/material";
import LottieLoadingIndicator from "../../../../shared/components/LottieLoadingIndicator";
import ToastBar from "../../../../shared/components/ToastBar";
import { PartnerUserRole, User } from "../../../../graphql/generated/graphql";
import { graphql } from "../../../../graphql/generated";
import ErrorIndicator from "../../../../shared/components/ErrorIndicator";

export const OEM_USERS = graphql(`
  query oemUserList($oemShortname: String!) {
    userList(oemShortname: $oemShortname) {
      email
      name
      id
      picture
      role
    }
  }
`);

export const DELETE_USER_FROM_TENANT = graphql(`
  mutation deleteUser($oemShortname: String!, $userId: String!) {
    deleteUserFromTenant(oemShortname: $oemShortname, userId: $userId)
  }
`);

export const SET_PARTNER_USER_ROLE = graphql(`
  mutation setPartnerUserRole(
    $oemShortname: String!
    $userId: String!
    $role: PartnerUserRole!
  ) {
    setPartnerUserRole(
      oemShortname: $oemShortname
      userId: $userId
      role: $role
    )
  }
`);

export default function UserList({
  isAdmin,
  oemShortname,
}: {
  isAdmin: boolean;
  oemShortname: string;
}) {
  const { user: currentUser } = useAuth0();
  const { data, error, loading, refetch } = useQuery(OEM_USERS, {
    variables: { oemShortname: oemShortname },
  });
  const { showModal } = useDeleteModalContext();
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showFailure, setShowFailure] = useState(false);

  const [deleteUserMutation] = useMutation(DELETE_USER_FROM_TENANT);
  const [setUserRoleMutation] = useMutation(SET_PARTNER_USER_ROLE);

  const [userBeingManaged, setUserBeingManaged] = useState<User | undefined>(
    undefined
  );
  const [userLoading, setUserLoading] = useState<User | undefined>(undefined);

  if (loading) {
    return LottieLoadingIndicator();
  }

  if (error || !data) {
    return ErrorIndicator();
  }

  const deleteUser = async (userId: string) => {
    try {
      await deleteUserMutation({
        variables: {
          oemShortname,
          userId,
        },
      });
    } catch {
      return setShowFailure(true);
    }

    setSuccessMessage(`User deleted successfully.`);
    setShowSuccess(true);
    await refetch();
  };

  const setUserRole = async (user: User, role: PartnerUserRole) => {
    // kind of gross way to ensure the loader sticks on the user being updated, but it works visually
    setUserLoading(user);
    await setUserRoleMutation({
      variables: { oemShortname, userId: user.id, role },
    });

    await refetch();
    setUserLoading(undefined);
  };

  return (
    <React.Fragment>
      <ul className={styles.userList}>
        {data.userList.map((user) => (
          <li key={user.id} className={styles.user}>
            <div className={styles.infoGroup}>
              <img
                className={styles.avatar}
                src={user.picture}
                alt={user.name}
              />

              <div className={styles.userInfo}>
                <div className={styles.userName}>
                  {(user.id === currentUser?.sub ? "(You) • " : "").concat(
                    user.name
                  )}
                </div>
                <div className={styles.userEmail}>{user.email}</div>
              </div>
            </div>

            <div className={styles.infoGroup}>
              {isAdmin && user.id !== currentUser?.sub ? (
                <div className={styles.menuContainer}>
                  {userLoading === user ? (
                    <div className={styles.roleDropdown}>
                      {LottieLoadingIndicator(20)}
                    </div>
                  ) : (
                    <React.Fragment>
                      <button
                        className={styles.roleDropdown}
                        onClick={(e) => {
                          e.stopPropagation();
                          setUserBeingManaged(
                            userBeingManaged !== user ? user : undefined
                          );
                        }}
                        data-testid={`role-${user.id}`}
                      >
                        {readableUserRole(user.role)} <ExpandMore />
                      </button>

                      {userBeingManaged === user && (
                        <OptionMenu
                          className={styles.roleOptions}
                          toggleOpen={() => setUserBeingManaged(undefined)}
                        >
                          {partnerRoleLabels.map((role) => (
                            <button
                              key={role.label}
                              className={classNames({
                                [styles.activeRole]:
                                  role.auth0Role === user.role,
                              })}
                              onClick={() => setUserRole(user, role.value)}
                              data-testid={`${role.label}-${user.id}`}
                            >
                              {role.label}
                            </button>
                          ))}
                        </OptionMenu>
                      )}
                    </React.Fragment>
                  )}
                </div>
              ) : (
                <div className={styles.active} data-testid="read-role">
                  {readableUserRole(user.role)}
                </div>
              )}

              {isAdmin && user.id !== currentUser?.sub && (
                <Tooltip title="Delete User">
                  <button
                    className={styles.deleteButton}
                    onClick={() => {
                      showModal(
                        user.id,
                        `This will permanently delete ${user.name}. This action cannot be undone.`,
                        () => deleteUser
                      );
                    }}
                    data-testid={`delete-${user.id}`}
                  >
                    <DeleteOutlined />
                  </button>
                </Tooltip>
              )}
            </div>
          </li>
        ))}
      </ul>

      <ToastBar
        type="success"
        message={successMessage}
        open={showSuccess}
        onClose={() => setShowSuccess(false)}
      />

      <ToastBar
        type="error"
        message="Failed to modify user invite. Please contact support."
        open={showFailure}
        onClose={() => setShowFailure(false)}
      />
    </React.Fragment>
  );
}
