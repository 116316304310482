import React from "react";
import { Container } from "@mui/material";
import styles from "./MainContainer.module.css";

export default function MainContainer(props: React.PropsWithChildren<object>) {
  return (
    <Container component="main" maxWidth="lg" className={styles.container}>
      {props.children || <div />}
    </Container>
  );
}
