import { Tooltip } from "@mui/material";
import { FileDownloadOutlined } from "@mui/icons-material";
import React from "react";
import { downloadWithAuth } from "../../../../utils/downloadWithAuth";
import { useAuth0 } from "@auth0/auth0-react";
import { useAnalytics } from "@btr-energy/analytics";

export function DownloadPartnerDocumentButton({
  fileName,
  partnerName,
}: {
  fileName: string;
  partnerName: string;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const analytics = useAnalytics();

  const downloadDocument = async () => {
    await downloadWithAuth({
      endpoint: `partner-dashboard/partner-documents/${partnerName}/${fileName}`,
      token: await getAccessTokenSilently(),
    });
  };

  return (
    <Tooltip title="Download Document">
      <button
        data-testid="partner-download-button"
        style={{
          color: "#7d8493",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "1.5px solid var(--color-dashboard-dividers)",
          width: "40px",
          height: "40px",
          borderRadius: "8px",
        }}
        onClick={() => {
          analytics.track({ name: "Document Downloaded" });
          void downloadDocument();
        }}
      >
        <FileDownloadOutlined />
      </button>
    </Tooltip>
  );
}
