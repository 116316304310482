import MainContainer from "./MainContainer";
import { Alert, AlertTitle } from "@mui/material";
import React from "react";

export default function ErrorIndicator() {
  return (
    <MainContainer>
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        An Error Occurred
      </Alert>
    </MainContainer>
  );
}
