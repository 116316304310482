import React, { createContext, ReactNode, useContext, useState } from "react";
import { Modal } from "@mui/material";
import styles from "./DeleteModal.module.css";
import LottieLoadingIndicator from "../LottieLoadingIndicator";
import classNames from "classnames";

type DeleteModalContext = {
  showModal: (
    idToDelete: string,
    message: string,
    performDelete: () => void
  ) => void;
  hideModal: () => void;
};

const initialState: DeleteModalContext = {
  showModal: () => {},
  hideModal: () => {},
};

const ModalContext = createContext(initialState);
export const useDeleteModalContext = () => useContext(ModalContext);

export default function DeleteModal({ children }: { children: ReactNode }) {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [idToDelete, setIdToDelete] = useState("");
  const [performDelete, setPerformDelete] = useState<(id: string) => void>(
    () => {}
  );

  const confirmAction = async () => {
    setLoading(true);
    performDelete(idToDelete);
    hideModal();
    setLoading(false);
  };

  const showModal = (
    idToDelete: string,
    message: string,
    doDelete: (id: string) => void
  ) => {
    setIsModalOpen(true);
    setIdToDelete(idToDelete);
    setMessage(message);
    setPerformDelete(doDelete);
  };

  const hideModal = () => setIsModalOpen(false);

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      <Modal
        className={styles.modal}
        title="Confirm Delete"
        data-testid={"delete-modal-test-id"}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        slotProps={{
          backdrop: { style: { background: "#B3B6BC", opacity: "0.6" } },
        }}
      >
        <div className={styles.content}>
          <div>
            <div className={styles.title}>Delete this user?</div>
            <div className={styles.message}>{message}</div>
          </div>

          <div className={styles.buttonGroup}>
            <button
              className={styles.cancelButton}
              onClick={hideModal}
              data-testid={"delete-cancel"}
            >
              Cancel
            </button>
            {loading ? (
              <button
                className={classNames(styles.submitButton, styles.loader)}
              >
                {LottieLoadingIndicator(40)}
              </button>
            ) : (
              <button
                className={styles.submitButton}
                onClick={confirmAction}
                data-testid={"delete-confirm"}
              >
                Delete
              </button>
            )}
          </div>
        </div>
      </Modal>
      {children}
    </ModalContext.Provider>
  );
}
