import React, { createContext, useContext, useEffect, useState } from "react";

export type Query = {
  date: number;
  name: string;
  query: string;
};

const CHAT_BTR_STORAGE_KEY = "CHAT_BTR_QUERIES" + process.env.REACT_APP_API_URI;

type SavedQueriesContextType = {
  savedQueries: Query[];
  saveQuery: (query: Query, index?: number | null) => void;
  removeQuery: (query: Query) => void;
  retrieveQueries: () => Query[];
};

const SavedQueriesContext = createContext<SavedQueriesContextType>(
  {} as SavedQueriesContextType
);

export function SavedQueriesProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [savedQueries, setSavedQueries] = useState<Query[]>([]);

  const retrieveQueries = (): Query[] => {
    const storedQueries = localStorage.getItem(CHAT_BTR_STORAGE_KEY);
    if (storedQueries) {
      return (JSON.parse(storedQueries) as Query[]).sort(
        (a, b) => b.date - a.date
      );
    }
    return [];
  };

  const saveQueriesToStorage = (queries: Query[]) => {
    localStorage.setItem(CHAT_BTR_STORAGE_KEY, JSON.stringify(queries));
  };

  useEffect(() => {
    const storedQueries = retrieveQueries();
    setSavedQueries(storedQueries);
  }, []);

  const saveQuery = (query: Query, index: number | null = null) => {
    setSavedQueries((prevQueries) => {
      const updatedQueries = [...prevQueries];

      if (index !== null && index >= 0 && index < updatedQueries.length) {
        updatedQueries[index] = query;
      } else {
        updatedQueries.push(query);
      }

      saveQueriesToStorage(updatedQueries);
      return updatedQueries;
    });
  };

  const removeQuery = (query: Query) => {
    setSavedQueries((prevQueries) => {
      const updatedQueries = prevQueries.filter((q) => q !== query);
      saveQueriesToStorage(updatedQueries);
      return updatedQueries;
    });
  };

  return (
    <SavedQueriesContext.Provider
      value={{
        savedQueries,
        saveQuery,
        removeQuery,
        retrieveQueries,
      }}
    >
      {children}
    </SavedQueriesContext.Provider>
  );
}

export const useSavedQueries = () => useContext(SavedQueriesContext);
