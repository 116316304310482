import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import GraphQLUpload from "../../../../shared/components/GraphQLUpload";
import { Box, Card, CardContent, CardHeader, Grid } from "@mui/material";
import ClearableOemFilter from "../../../../shared/components/ClearableOemFilter";
import YearQuarterAutocomplete from "../../../../shared/components/YearQuarterAutocomplete";
import { QuarterOptions } from "../../../../App/QuarterOptions";
import { graphql } from "../../../../graphql/generated";
import {
  Oem,
  RegistrationType,
  YearQuarter,
} from "../../../../graphql/generated/graphql";

export const UPLOAD_REGISTRATIONS = graphql(`
  mutation uploadRegistrations(
    $yearQuarter: YearQuarter!
    $files: [Upload!]!
    $type: RegistrationType!
    $oemShortName: String!
  ) {
    uploadRegistrations(
      yearQuarter: $yearQuarter
      files: $files
      type: $type
      oemShortName: $oemShortName
    ) {
      added
    }
  }
`);

export default function RegistrationUpload({
  currentDate = new Date(),
  postUpload,
}: {
  currentDate?: Date;
  postUpload: () => void;
}) {
  const [mutationUpload, { loading }] = useMutation(UPLOAD_REGISTRATIONS);

  const [chosenQuarter, setChosenQuarter] = useState<YearQuarter>(
    QuarterOptions.fromDate(currentDate)
  );

  const [chosenOem, setChosenOem] = useState<Oem | null>(null);

  function changeOem(_: any, newValue: Oem | null) {
    setChosenOem(newValue);
  }

  function changeQuarter(_: any, newValue: YearQuarter) {
    setChosenQuarter(newValue);
  }

  return (
    <Card>
      <CardHeader title="Upload Vehicles" />
      <CardContent>
        <Box display="flex" flexDirection="row" py={1}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <ClearableOemFilter
                changeOem={changeOem}
                size={"small"}
                testid={"registration-oem-filter-selector"}
              />
            </Grid>
            <Grid item xs={3}>
              <YearQuarterAutocomplete
                changeQuarter={changeQuarter}
                testId={"registration-quarter-filter-autocomplete"}
                size={"small"}
              />
            </Grid>
          </Grid>
        </Box>

        <GraphQLUpload
          accept={[".json", ".csv", ".xlsx"]}
          elementName="Registrations"
          loading={loading}
          upload={async (files: File[]) => {
            if (!chosenOem) return;

            return (
              await mutationUpload({
                variables: {
                  files: files,
                  yearQuarter: chosenQuarter,
                  type: RegistrationType.PassengerEv,
                  oemShortName: chosenOem.shortName,
                },
              })
            )?.data?.uploadRegistrations?.added;
          }}
          postUpload={postUpload}
          requiredFields={[chosenOem?.shortName]}
        />
      </CardContent>
    </Card>
  );
}
