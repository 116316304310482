import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../lotties/loading.json";

export default function LottieLoadingIndicator(size = 80) {
  const pxSize = size + "px";
  return (
    <div style={{ margin: "auto" }}>
      <Player
        style={{ width: pxSize, height: pxSize }}
        autoplay
        loop
        src={animationData}
      />
    </div>
  );
}
