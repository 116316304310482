import React, { useState } from "react";
import AdminApp from "../AdminDashboard/AdminApp";
import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import NoRoleErrorPage from "../shared/components/NoRoleErrorPage";
import LottieLoadingIndicator from "../shared/components/LottieLoadingIndicator";
import { graphql } from "../graphql/generated";
import { Role } from "../graphql/generated/graphql";
import PartnerAppUserWrapper from "../PartnerDashboards/PartnerAppUserWrapper";
import PartnerAppAdminWrapper from "../PartnerDashboards/PartnerAppAdminWrapper";
import ChatBTR from "../ChatBTR/ChatBTR";
import { useAuth0 } from "@auth0/auth0-react";

export const GET_ROLE = graphql(`
  query getUserRole {
    roleOfCurrentUser
  }
`);

export default function AppBodyWrapper() {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const { loading: roleLoading, data } = useQuery(GET_ROLE, {
    skip: isLoading || !isAuthenticated,
  });
  const [showLegacyAdmin, setShowLegacyAdmin] = useState(false);
  const [showChatBtr, setShowChatBtr] = useState(false);

  const role = data?.roleOfCurrentUser;

  const togglePortal = () => setShowLegacyAdmin(!showLegacyAdmin);
  const toggleChatBtr = () => setShowChatBtr(!showChatBtr);

  if (isLoading || roleLoading) {
    return LottieLoadingIndicator();
  }

  if (!isAuthenticated) {
    const queryParams = new URLSearchParams(useLocation().search);

    const invitation = queryParams.get("invitation");
    const organization = queryParams.get("organization");
    if (invitation != null && organization != null) {
      void loginWithRedirect({
        authorizationParams: {
          redirect_uri: `${process.env.REACT_APP_BASE_URI}`,
          invitation: invitation,
          organization: organization,
        },
      });
    } else {
      void loginWithRedirect();
    }

    return LottieLoadingIndicator();
  }

  if (role === undefined) {
    return <NoRoleErrorPage />;
  }

  switch (role) {
    case Role.BtrAdmin:
      if (showLegacyAdmin) {
        return <AdminApp togglePortal={togglePortal} />;
      } else if (showChatBtr) {
        return <ChatBTR toggleChatBtr={toggleChatBtr} />;
      } else {
        return (
          <PartnerAppAdminWrapper
            togglePortal={togglePortal}
            toggleChatBtr={toggleChatBtr}
          />
        );
      }
    case Role.OemAdmin:
      return <PartnerAppUserWrapper />;
    case Role.None:
      return <NoRoleErrorPage />;
  }
}
