const BYTES_PER_KB = 1_000;
const BYTES_PER_MB = BYTES_PER_KB * BYTES_PER_KB;

export function displayFileSize(bytes: number) {
  if (bytes < BYTES_PER_KB) {
    return `${(bytes / BYTES_PER_KB).toFixed(2)} kB`;
  }

  if (bytes < BYTES_PER_MB) {
    return `${(bytes / BYTES_PER_KB).toFixed(0)} kB`;
  }

  return `${(bytes / BYTES_PER_MB).toFixed(2)} MB`;
}
