import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Modal,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import GraphQLUpload from "../../../shared/components/GraphQLUpload";
import { useMutation, useQuery } from "@apollo/client";
import { QueryResult } from "@apollo/client";
import MainContainer from "../../../shared/components/MainContainer";
import LoadingIndicator from "../../../shared/components/LoadingIndicator";
import YearQuarterAutocomplete from "../../../shared/components/YearQuarterAutocomplete";
import { QuarterOptions } from "../../../App/QuarterOptions";
import { OfflinePin } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import { graphql } from "../../../graphql/generated";
import {
  PublicChargingStationList,
  PublicChargingStationListsQuery,
  PublicChargingStationListsQueryVariables,
  YearQuarter,
} from "../../../graphql/generated/graphql";

export const UPLOAD_CHARGING_STATIONS = graphql(`
  mutation uploadChargingStations($file: Upload!, $carbUpdateDate: String!) {
    uploadChargingStations(file: $file, carbUpdateDate: $carbUpdateDate) {
      replaced
    }
  }
`);

export const FINALIZE_CHARGING_STATIONS = graphql(`
  mutation markStationListAsFinal(
    $chargingStationList: String!
    $yearQuarter: YearQuarter!
  ) {
    markStationListAsFinal(
      chargingStationList: $chargingStationList
      yearQuarter: $yearQuarter
    )
  }
`);

export const CHARGING_STATIONS = graphql(`
  query publicChargingStationLists {
    publicChargingStationLists {
      count
      uploadedDate
      carbUpdateDate
      carbListIdentifier
      finalForQuarters
    }
  }
`);

function toDisplayableDate(dateString: string) {
  const date = new Date(dateString);
  const month = date.toLocaleDateString("default", {
    month: "long",
    timeZone: "UTC",
  });

  return `${month} ${date.getUTCDate()}, ${date.getFullYear()}`;
}

function uploadedChargingStations(
  query: QueryResult<
    PublicChargingStationListsQuery,
    PublicChargingStationListsQueryVariables
  >,
  openModal: React.Dispatch<React.SetStateAction<boolean>>,
  updateStation: React.Dispatch<
    React.SetStateAction<PublicChargingStationList | undefined>
  >
) {
  const { error, data, loading } = query;

  if (error) {
    return <p>An error has occurred.</p>;
  }

  if (loading) {
    return LoadingIndicator(0);
  }

  if (
    data?.publicChargingStationLists &&
    data.publicChargingStationLists.length > 0
  ) {
    return [...data.publicChargingStationLists]
      ?.sort((stationList1, stationList2) => {
        const date1 = new Date(stationList1.uploadedDate);
        const date2 = new Date(stationList2.uploadedDate);

        if (date1 > date2) return -1;
        if (date1 < date2) return 1;
        return 0;
      })
      .map((stationList: PublicChargingStationList) => {
        return (
          <div
            key={stationList.carbListIdentifier}
            style={{ marginBottom: "10px" }}
          >
            <Grid container>
              <Grid item xs={1}>
                <Typography variant="overline" gutterBottom>
                  Stations
                </Typography>
                <Typography variant="h6" gutterBottom>
                  {stationList.count.toLocaleString()}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="overline" gutterBottom>
                  Uploaded
                </Typography>
                <Typography variant="h6" gutterBottom>
                  {toDisplayableDate(stationList.uploadedDate)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="overline" gutterBottom>
                  Updated By CARB
                </Typography>
                <Typography variant="h6" gutterBottom>
                  {toDisplayableDate(stationList.carbUpdateDate)}
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                style={
                  stationList.finalForQuarters.length === 0
                    ? { display: "none" }
                    : {}
                }
              >
                <Typography variant="overline" gutterBottom>
                  Quarters finalized with this list
                </Typography>
                <Typography variant="h6" gutterBottom>
                  {stationList.finalForQuarters.join(", ")}
                </Typography>
              </Grid>
              <Box pr={6} alignSelf="center" marginLeft="auto">
                <Tooltip title="Mark Station List as Final">
                  <IconButton
                    data-testid="mark-station-as-final"
                    onClick={() => {
                      updateStation(stationList);
                      openModal(true);
                    }}
                  >
                    <OfflinePin />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
            <Divider />
          </div>
        );
      });
  }
}

export default function ChargingStationsComponent() {
  const stationQuery = useQuery(CHARGING_STATIONS);
  const [mutationUpload, { loading }] = useMutation(UPLOAD_CHARGING_STATIONS);

  const [mutationFinalize, { loading: markLoading }] = useMutation(
    FINALIZE_CHARGING_STATIONS
  );

  const [confirming, setConfirming] = useState<boolean>(false);

  const defaultDate = new Date();
  const [selectedDate, setSelectedDate] = useState<Date>(defaultDate);

  const [markFinalOpen, setMarkFinalOpen] = useState<boolean>(false);

  const [chosenStationList, setChosenStationList] = useState<
    PublicChargingStationList | undefined
  >(undefined);
  const [chosenQuarter, setChosenQuarter] = useState<QuarterOptions>(
    QuarterOptions.fromDate(new Date())
  );

  let finalizedQuarters: string[] = [];
  if (stationQuery.data != null) {
    const lists = stationQuery.data.publicChargingStationLists;
    finalizedQuarters = lists.flatMap((it) => {
      return it.finalForQuarters;
    });
  }

  function changeQuarter(e: any, newValue: YearQuarter): void {
    setChosenQuarter(QuarterOptions.fromYQ(newValue));
    setConfirming(false);
  }

  const [displaySuccess, setDisplaySuccess] = useState(false);

  const handleSuccessClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setDisplaySuccess(false);
  };

  return (
    <MainContainer>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Upload Public Charging Stations" />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DatePicker
                    inputFormat="MM/dd/yyyy"
                    label="Start Date"
                    value={selectedDate}
                    onChange={(date) => {
                      if (date) setSelectedDate(date);
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          "data-testid": "charging-station-carb-date",
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <GraphQLUpload
                    accept={[".xlsx"]}
                    elementName="Public Charging Stations"
                    loading={loading}
                    upload={async (files: File[]) =>
                      (
                        await mutationUpload({
                          variables: {
                            file: files[0],
                            carbUpdateDate: selectedDate
                              .toISOString()
                              .split("T")[0],
                          },
                        })
                      )?.data?.uploadChargingStations.replaced
                    }
                    postUpload={stationQuery.refetch}
                    multiFileUpload={false}
                    testId="charging-stations-upload-input"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader title="Previously Uploaded Charging Stations" />
            <CardContent style={{ maxHeight: "475px", overflow: "auto" }}>
              {uploadedChargingStations(
                stationQuery,
                setMarkFinalOpen,
                setChosenStationList
              )}
            </CardContent>
          </Card>
        </Grid>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          data-testid={"modal-test-id"}
          open={markFinalOpen}
          onClose={() => {
            setMarkFinalOpen(false);
          }}
        >
          <Card>
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ padding: "10px", fontSize: "20px" }}>
                Marking station list uploaded on
              </div>
              <b style={{ fontSize: "24px" }}>
                {chosenStationList
                  ? toDisplayableDate(chosenStationList.uploadedDate)
                  : ""}
              </b>
              <div style={{ padding: "10px", fontSize: "20px" }}>
                as final for:
              </div>
              <div style={{ width: "75%" }} data-testid={"modal-contents"}>
                <YearQuarterAutocomplete
                  changeQuarter={changeQuarter}
                  size="small"
                  testId={"yearquarter-select-finalize"}
                />
              </div>
              <div
                hidden={!finalizedQuarters.includes(chosenQuarter.label)}
                style={{
                  fontWeight: "bold",
                  color: "red",
                  padding: "10px 10px 0px 10px",
                }}
              >
                This quarter has already been finalized
              </div>
              <Button
                size="medium"
                style={{ margin: "15px", padding: "8px 15px 8px 15px" }}
                disabled={
                  markLoading || finalizedQuarters.includes(chosenQuarter.label)
                }
                data-testid="submit-finalization"
                onClick={async () => {
                  if (confirming && chosenStationList) {
                    await mutationFinalize({
                      variables: {
                        chargingStationList:
                          chosenStationList.carbListIdentifier,
                        yearQuarter: {
                          year: chosenQuarter.year,
                          quarter: chosenQuarter.quarter,
                        },
                      },
                    }).then(async () => {
                      setMarkFinalOpen(false);
                      setDisplaySuccess(true);
                      await stationQuery.refetch();
                    });
                  } else {
                    setConfirming(true);
                  }
                }}
              >
                {confirming ? "Confirm submission" : "Submit"}
              </Button>
            </CardContent>
          </Card>
        </Modal>
        <Snackbar
          data-testid="message-success"
          open={displaySuccess}
          autoHideDuration={6000}
          onClose={handleSuccessClose}
        >
          <Alert elevation={6} variant="filled" severity="success">
            Successfully Marked Station List as Final
          </Alert>
        </Snackbar>
      </Grid>
    </MainContainer>
  );
}
