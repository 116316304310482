import React, { createContext, useContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PartnerUserRole } from "../graphql/generated/graphql";
import { translateClaimsToRoles } from "../utils/partnerUserRoles";

interface RoleContextType {
  roles: PartnerUserRole[];
  isLoading: boolean;
}

export const RoleContext = createContext<RoleContextType>(
  {} as RoleContextType
);

export function RoleProvider({ children }: { children: React.ReactNode }) {
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const [roles, setRoles] = useState<PartnerUserRole[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isAuthenticated) return;

    const fetchRoles = async () => {
      setIsLoading(true);
      const claims = await getIdTokenClaims();
      const fetchedRoles = translateClaimsToRoles(claims);
      setRoles(fetchedRoles);

      setIsLoading(false);
    };

    void fetchRoles();
  }, [isAuthenticated, getIdTokenClaims]);

  const value = { roles, isLoading };

  return <RoleContext.Provider value={value}>{children}</RoleContext.Provider>;
}

export const useRoles = () => useContext(RoleContext);
