import { FC } from "react";

interface WashingtonIconProps {
  size?: number;
}

const WashingtonIcon: FC<WashingtonIconProps> = ({ size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" stroke="#07174F" strokeWidth="2" />
      <path
        d="M6.732 16L5 9H6.39801L7.39997 13.8638H7.45021L8.55562 9H9.75265L10.8551 13.874H10.9083L11.9103 9H13.3083L11.5763 16H10.329L9.1763 11.4233H9.12901L7.97927 16H6.732Z"
        fill="#07174F"
      />
      <path
        d="M14.5459 16H13.1745L15.2641 9H16.9133L19 16H17.6286L16.1124 10.5996H16.0651L14.5459 16ZM14.4602 13.2485H17.6995V14.4038H14.4602V13.2485Z"
        fill="#07174F"
      />
    </svg>
  );
};

export default WashingtonIcon;
