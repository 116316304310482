import React, { useEffect, useState } from "react";
import styles from "./SupersetDeployButton.module.css";
import { useMutation } from "@apollo/client";
import LottieLoadingIndicator from "../../shared/components/LottieLoadingIndicator";
import ToastBar from "../../shared/components/ToastBar";
import { graphql } from "../../graphql/generated";

export const SUPERSET_DASHBOARD_DEPLOY = graphql(`
  mutation deployDashboard($dashboardSlug: String!) {
    deployDashboard(dashboardSlug: $dashboardSlug)
  }
`);

export default function SupersetDeployButton({
  dashboardSlug,
  canDeployDashboard,
}: {
  dashboardSlug: string;
  canDeployDashboard: boolean;
}) {
  const [deployDashboard, { loading, data, error }] = useMutation(
    SUPERSET_DASHBOARD_DEPLOY
  );

  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);

  const deploy = async () =>
    await deployDashboard({
      variables: {
        dashboardSlug: dashboardSlug,
      },
    });

  useEffect(() => {
    if (error) setShowFailure(true);
    if (!data) return;

    data.deployDashboard ? setShowSuccess(true) : setShowFailure(true);
  }, [data, error]);

  if (
    !canDeployDashboard ||
    process.env.REACT_APP_DASHBOARD_DEPLOY_ALLOWED !== "true"
  ) {
    return <div />;
  }

  return (
    <React.Fragment>
      <div className={styles.wrapper}>
        {loading ? (
          LottieLoadingIndicator()
        ) : (
          <button onClick={deploy} className={styles.deployBtn}>
            Deploy Dashboard
          </button>
        )}
      </div>

      <ToastBar
        type="success"
        message="Successfully initiated dashboard deployment."
        open={showSuccess}
        onClose={() => setShowSuccess(false)}
      />

      <ToastBar
        type="error"
        message="Failed to deploy dashboard: Not enough initiators."
        open={showFailure}
        onClose={() => setShowFailure(false)}
      />
    </React.Fragment>
  );
}
