import { FileType } from "../graphql/generated/graphql";

export function getFileTypeName(type: FileType): string {
  switch (type) {
    case FileType.Audit:
      return "Audit";
    case FileType.UnfilteredWithVin:
      return "Unfiltered with VIN";
    case FileType.UnfilteredWithBtrid:
      return "Unfiltered with BTR Id";
  }
}

export function getFileTypeLayout(type: FileType): string {
  switch (type) {
    case FileType.Audit:
      return "CSV:\nFSE_ID,Watt_hours";
    case FileType.UnfilteredWithVin:
      return "CSV:\nVIN,Start_Year,Start_Month,Start_Day,Start_Hour,Start_Minute,End_Year,End_Month,End_Day,End_Hour,End_Minute,Latitude,Longitude,Start_Battery,End_Battery";
    case FileType.UnfilteredWithBtrid:
      return "CSV:\nId(unused),BTR_ID,Start_Year,Start_Month,Start_Day,Start_Hour,Start_Minute,End_Year,End_Month,End_Day,End_Hour,End_Minute,Latitude,Longitude,Start_Battery,End_Battery";
  }
}
