import { Navigate, Route, Routes } from "react-router-dom";
import React, { useState } from "react";
import Footer from "./Footer";
import Sidenav from "./Sidenav/Sidenav";
import styles from "./PartnerApp.module.css";
import AccountManagement from "./shared/views/AccountManagement/AccountManagement";
import NotFound from "../shared/components/NotFound";
import Resources from "./shared/views/Resources/Resources";
import SupersetDashboard from "./Superset/SupersetDashboard";
import Notifications from "./shared/views/Notifications/Notifications";
import LoginActivity from "./shared/views/LoginActivity/LoginActivity";
import ProtectedRoutes from "./ProtectedRoutes";
import {
  PartnerUserRole,
  UserPartnerQuery,
} from "../graphql/generated/graphql";

export default function PartnerApp({
  defaultRoute,
  partner,
  togglePortal = undefined,
  toggleChatBtr = undefined,
}: {
  defaultRoute: string;
  partner: UserPartnerQuery["currentUserPartner"];
  togglePortal?: () => void;
  toggleChatBtr?: () => void;
}) {
  const [openNav, setOpenNav] = useState(true);
  const toggleNav = () => setOpenNav(!openNav);

  return (
    <div className={styles.dashboard}>
      <Sidenav
        open={openNav}
        toggleOpen={toggleNav}
        partner={partner}
        togglePortal={togglePortal}
        toggleChatBtr={toggleChatBtr}
      />

      <main className={styles.content}>
        <Routes>
          <Route
            path={"/lcfs"}
            element={
              <ProtectedRoutes
                allowedRoles={[
                  PartnerUserRole.Admin,
                  PartnerUserRole.User,
                  PartnerUserRole.UserDeveloper,
                ]}
              >
                <SupersetDashboard
                  key="partner-california-etlantis"
                  dashboardSlug="partner-california-etlantis"
                  partnerId={partner.id}
                />
              </ProtectedRoutes>
            }
          />

          <Route
            path={"/lcfs/charging-insights"}
            element={
              <ProtectedRoutes
                allowedRoles={[
                  PartnerUserRole.Admin,
                  PartnerUserRole.User,
                  PartnerUserRole.UserDeveloper,
                ]}
              >
                <SupersetDashboard
                  key="partner-california-charging-insights-etlantis"
                  dashboardSlug="partner-california-charging-insights-etlantis"
                  partnerId={partner.id}
                />
              </ProtectedRoutes>
            }
          />

          <Route
            path={"/lcfs/market-insights"}
            element={
              <ProtectedRoutes
                allowedRoles={[
                  PartnerUserRole.Admin,
                  PartnerUserRole.User,
                  PartnerUserRole.UserDeveloper,
                ]}
              >
                <SupersetDashboard
                  key="partner-california-market-insights"
                  dashboardSlug="partner-california-market-insights"
                  partnerId={partner.id}
                />
              </ProtectedRoutes>
            }
          />

          <Route
            path={"/washington"}
            element={
              <ProtectedRoutes
                allowedRoles={[
                  PartnerUserRole.Admin,
                  PartnerUserRole.User,
                  PartnerUserRole.UserDeveloper,
                ]}
                necessaryLocation={"US-WA"}
                oemShortname={partner.shortName}
              >
                <SupersetDashboard
                  key="partner-washington"
                  dashboardSlug="partner-washington"
                  partnerId={partner.id}
                />
              </ProtectedRoutes>
            }
          />

          <Route
            path={"/developer-tools"}
            element={
              <ProtectedRoutes
                allowedRoles={[
                  PartnerUserRole.Admin,
                  PartnerUserRole.UserDeveloper,
                  PartnerUserRole.Developer,
                ]}
              >
                <SupersetDashboard
                  key="data-activity"
                  dashboardSlug="data-activity"
                  partnerId={partner.id}
                />
              </ProtectedRoutes>
            }
          />

          <Route
            path={"resources"}
            element={
              <ProtectedRoutes
                allowedRoles={[
                  PartnerUserRole.Admin,
                  PartnerUserRole.User,
                  PartnerUserRole.UserDeveloper,
                ]}
              >
                <Resources partner={partner} />
              </ProtectedRoutes>
            }
          />

          <Route path={"notifications"} element={<Notifications />} />
          <Route
            path={"accounts"}
            element={<AccountManagement oemShortname={partner.shortName} />}
          />
          <Route path={"login-activity"} element={<LoginActivity />} />
          <Route path="/" element={<Navigate replace to={defaultRoute} />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}
