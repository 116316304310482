import {
  SubmittedBatch,
  YearQuarter,
} from "../../../../graphql/generated/graphql";

export class SubmittedBatchesPresenter {
  readonly batches: SubmittedBatch[];
  constructor(
    submittedBatches: SubmittedBatch[],
    chosenYearQuarter: YearQuarter | null
  ) {
    this.batches = submittedBatches.filter((b) => {
      return (
        chosenYearQuarter === null ||
        (chosenYearQuarter.year === b.yearQuarter.year &&
          chosenYearQuarter.quarter === b.yearQuarter.quarter)
      );
    });
  }
}
