import {
  RegistrationDashboardQuery,
  YearQuarter,
} from "../../../../graphql/generated/graphql";

export class RegistrationStatusPresenter {
  public readonly counts: {
    approved: number;
    unsubmittedAll: number;
    unsubmittedCarb: number;
    rejected: number;
    pending: number;
    submitted: number;
    deactivated: number;
  };
  public readonly rateOfRejection: string;

  constructor(
    countsPerOem: RegistrationDashboardQuery["allOemRegistrationsByQuarter"],
    chosenOemId: number,
    chosenYearQuarter: YearQuarter | null
  ) {
    this.counts = countsPerOem
      .filter((c) => c.oem.id === chosenOemId || chosenOemId === 0)
      .filter((c) => {
        return (
          chosenYearQuarter === null ||
          (c.yearQuarter.year === chosenYearQuarter.year &&
            c.yearQuarter.quarter === chosenYearQuarter.quarter)
        );
      })
      .reduce(
        (acc, val) => {
          return {
            approved: acc.approved + val.approved,
            unsubmittedAll: acc.unsubmittedAll + val.unsubmittedAll,
            unsubmittedCarb: acc.unsubmittedCarb + val.unsubmittedCarb,
            rejected: acc.rejected + val.rejected,
            pending: acc.pending + val.pending,
            submitted: acc.submitted + val.submitted,
            deactivated: acc.deactivated + val.deactivated,
          };
        },
        {
          approved: 0,
          unsubmittedAll: 0,
          unsubmittedCarb: 0,
          rejected: 0,
          pending: 0,
          submitted: 0,
          deactivated: 0,
        }
      );

    this.rateOfRejection =
      this.counts.submitted === 0
        ? "0%"
        : `${((this.counts.rejected / this.counts.submitted) * 100).toFixed(
            2
          )}%`;
  }
}
