import React, { useEffect, useState } from "react";
import UserList from "./UserList";
import InvitedUserList from "./InvitedUserList";
import DeleteModal from "../../../../shared/components/modals/DeleteModal";
import styles from "./AccountManagement.module.css";
import InviteUser from "./InviteUser";
import { PartnerUserRole } from "../../../../graphql/generated/graphql";
import { ALL_PARTNER } from "../../../../shared/components/PartnerFilter";
import NoPartnerSelected from "../../../../shared/components/NoPartnerSelected";
import { useRoles } from "../../../../App/RolesContext";

export default function AccountManagement({
  oemShortname,
}: {
  oemShortname: string;
}) {
  const { roles } = useRoles();

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const hasAdminRole = roles.some(
      (r) => r === PartnerUserRole.Internal || r === PartnerUserRole.Admin
    );
    setIsAdmin(hasAdminRole);
  }, []);

  if (oemShortname === ALL_PARTNER.shortName) {
    return <NoPartnerSelected />;
  }

  return (
    <div className={styles.container}>
      <DeleteModal>
        <h1 className={styles.header}>Account Management</h1>
        {isAdmin && (
          <div className={styles.inviteContainer}>
            <InviteUser oemShortname={oemShortname} />
          </div>
        )}
        <h2 className={styles.title}>Pending Invites</h2>
        <InvitedUserList isAdmin={isAdmin} oemShortname={oemShortname} />
        <hr />
        <h2 className={styles.title}>Members</h2>
        <UserList isAdmin={isAdmin} oemShortname={oemShortname} />
      </DeleteModal>
    </div>
  );
}
