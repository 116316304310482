import React, { ReactNode } from "react";
import { Box, Divider } from "@mui/material";

export default function BetterCardHeader({
  children,
}: {
  children: ReactNode[] | ReactNode;
}) {
  return (
    <div>
      <Box style={{ display: "flex", alignItems: "center", padding: 16 }}>
        {children}
      </Box>
      <Divider />
    </div>
  );
}
