import React, { useRef, useState } from "react";
import { Modal } from "@mui/material";
import styles from "../styles/SavedQueries.module.css";
import { EditOutlined, RemoveRedEyeOutlined } from "@mui/icons-material";
import Button from "./Button";
import ToolTip from "./Tooltip";
import { Query, useSavedQueries } from "../SavedQueriesContext";

export default function SavedQueries({
  onLoadQuery,
}: {
  onLoadQuery: (query: Query) => void;
}) {
  const { savedQueries, saveQuery, removeQuery } = useSavedQueries();
  const codeRef = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);
  const [currentQuery, setCurrentQuery] = useState<Query | null>(null);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setCurrentQuery(null);
    setEditingIndex(null);
    setOpen(false);
  };

  const addNewQuery = () => {
    setCurrentQuery({ date: new Date().getTime(), name: "", query: "" });
    setEditingIndex(null);
    handleOpen();
  };

  const saveCurrentQuery = () => {
    if (currentQuery && codeRef.current) {
      const updatedQuery = {
        ...currentQuery,
        query: codeRef.current.textContent || "",
      };

      saveQuery(updatedQuery, editingIndex);
      handleClose();
    }
  };

  const deleteCurrentQuery = () => {
    if (currentQuery) {
      removeQuery(currentQuery);
    }
    handleClose();
  };

  const editQuery = (index: number) => {
    setCurrentQuery(savedQueries[index]);
    setEditingIndex(index);
    handleOpen();
  };

  const loadQuery = (query: Query) => onLoadQuery(query);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span>Saved Queries</span>
        <button className={styles.addQuery} onClick={addNewQuery}>
          +
        </button>
      </div>

      <div className={styles.queryList}>
        {savedQueries.length === 0 && (
          <div className={styles.empty}>No saved queries found</div>
        )}

        {savedQueries.map((query, index) => (
          <div key={index} className={styles.queryItem}>
            <button
              title={query.name}
              className={styles.loadQuery}
              onClick={() => loadQuery(query)}
            >
              <ToolTip content="Load query">
                {query.name || `Unnamed query`}
              </ToolTip>
            </button>

            <button onClick={() => navigator.clipboard.writeText(query.query)}>
              <ToolTip content={query.query}>
                <RemoveRedEyeOutlined />
              </ToolTip>
            </button>

            <button onClick={() => editQuery(index)}>
              <ToolTip content={"Edit query"}>
                <EditOutlined />
              </ToolTip>
            </button>
          </div>
        ))}
      </div>

      <Modal open={open} onClose={handleClose}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            {editingIndex === null ? `Add a saved query` : `Edit a saved query`}
          </div>

          <div className={styles.inputs}>
            <input
              className={styles.input}
              type="text"
              value={currentQuery?.name || ""}
              onChange={(e) =>
                currentQuery &&
                setCurrentQuery({ ...currentQuery, name: e.target.value })
              }
              placeholder="Query name"
            />

            <pre className={styles.pre}>
              <code
                ref={codeRef}
                contentEditable
                suppressContentEditableWarning={true}
              >
                {currentQuery?.query || "Add your query here"}
              </code>
            </pre>
          </div>

          <div className={styles.modalActions}>
            <Button variant="warn" onClick={deleteCurrentQuery}>
              Delete
            </Button>

            <div className={styles.buttonGroup}>
              <Button onClick={saveCurrentQuery}>Save</Button>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
