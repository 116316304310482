import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { UserPartnerQuery } from "../../graphql/generated/graphql";
import { graphql } from "../../graphql/generated";
import OptionMenu from "./OptionMenu";

import styles from "./PartnerFilter.module.css";
import { ExpandMore } from "@mui/icons-material";

export const PARTNER_FILTER_QUERY = graphql(`
  query partnerFilter {
    oems {
      id
      shortName
      logoUrl
      fullName
    }
  }
`);

export const ALL_PARTNER = {
  id: -1,
  shortName: "Bridge Admin",
  logoUrl: "/logo.png",
  fullName: "BTR Energy",
};

export default function PartnerFilter({
  currentPartner,
  setPartner,
}: {
  currentPartner: UserPartnerQuery["currentUserPartner"];
  setPartner: (value: UserPartnerQuery["currentUserPartner"]) => void;
}) {
  const { error, data, loading } = useQuery(PARTNER_FILTER_QUERY);
  const [open, setOpen] = useState(false);

  if (loading || error || !data) {
    return <div />;
  }

  const partnersSorted = [...data.oems].sort((a, b) =>
    a.shortName.localeCompare(b.shortName)
  );
  const options = [ALL_PARTNER, ...partnersSorted];

  return (
    <div>
      <button onClick={() => setOpen(!open)} className={styles.button}>
        {currentPartner.shortName}
        <ExpandMore />
      </button>

      {open && (
        <OptionMenu
          toggleOpen={() => setOpen(false)}
          className={styles.dropdown}
        >
          {options.map((partner) => (
            <button
              key={partner.id}
              onClick={() => {
                setOpen(false);
                setPartner(partner);
              }}
              className={partner.id === currentPartner.id ? styles.active : ""}
            >
              {partner.shortName}
            </button>
          ))}
        </OptionMenu>
      )}
    </div>
  );
}
