import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import LottieLoadingIndicator from "../shared/components/LottieLoadingIndicator";
import NoRoleErrorPage from "../shared/components/NoRoleErrorPage";
import { graphql } from "../graphql/generated";
import { PartnerUserRole } from "../graphql/generated/graphql";
import PartnerApp from "./PartnerApp";
import { useRoles } from "../App/RolesContext";

export const USER_PARTNER = graphql(`
  query userPartner {
    currentUserPartner {
      id
      logoUrl
      fullName
      shortName
    }
  }
`);

export default function PartnerAppUserWrapper() {
  const [defaultRoute, setDefaultRoute] = useState<string | undefined>(
    undefined
  );

  const { roles } = useRoles();

  const nonDeveloperRoles = [
    PartnerUserRole.Admin,
    PartnerUserRole.User,
    PartnerUserRole.UserDeveloper,
  ];

  useEffect(() => {
    const hasNonDevRoles = roles.some((r) => nonDeveloperRoles.includes(r));
    setDefaultRoute(hasNonDevRoles ? "/lcfs" : "developer-tools");
  }, []);

  const { data, loading, error } = useQuery(USER_PARTNER);

  if (loading || !defaultRoute) return LottieLoadingIndicator();

  if (error || !data?.currentUserPartner) return NoRoleErrorPage();

  const partner = data.currentUserPartner;

  return <PartnerApp defaultRoute={defaultRoute} partner={partner} />;
}
