import React from "react";
import styles from "./NotFound.module.css";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../lotties/404.json";

export default function NotFound() {
  const key = Math.random();

  return (
    <div key={key} className={styles.container}>
      <Player autoplay keepLastFrame src={animationData} />
      <div className={styles.title}>Sorry, we couldn’t find that page.</div>
      <div className={styles.message}>
        The page you’re searching for doesn’t exist anymore, either it has been
        moved or the link is broken. Please contact your administrator or email
        us at support@btr.energy for assistance.
      </div>
      <div className={styles.buttonGrp}>
        <a href={"mailto:support@btr.energy"} className={styles.contactBtn}>
          Contact us
        </a>
        <a href="/" className={styles.homeBtn}>
          Return home
        </a>
      </div>
    </div>
  );
}
