import { Quarter, YearQuarter } from "../graphql/generated/graphql";
import { quarterToNumber } from "../utils/quarterHelpers";

export class QuarterOptions {
  static fromYQ(yq: YearQuarter): QuarterOptions {
    return new QuarterOptions(yq.quarter, yq.year);
  }

  static from(option: string): QuarterOptions | null {
    const split = option.split("-");
    if (split.length !== 2) {
      return null;
    }
    const [quarter, year] = split;
    return new QuarterOptions(
      QuarterOptions.fromNumber(Number(quarter.toLowerCase().replace("q", ""))),
      Number(year)
    );
  }

  static fromDate(date: Date): QuarterOptions {
    return new QuarterOptions(
      QuarterOptions.quarterFrom(date),
      date.getFullYear()
    );
  }

  private static fromNumber(num: number) {
    switch (num) {
      case 1:
        return Quarter.First;
      case 2:
        return Quarter.Second;
      case 3:
        return Quarter.Third;
      default:
        return Quarter.Fourth;
    }
  }

  private static quarterFrom(date: Date) {
    const month = date.getMonth() + 1;
    switch (month) {
      case 1:
      case 2:
      case 3:
        return Quarter.First;
      case 4:
      case 5:
      case 6:
        return Quarter.Second;
      case 7:
      case 8:
      case 9:
        return Quarter.Third;
      case 10:
      case 11:
      case 12:
        return Quarter.Fourth;
      default:
        throw new Error(
          `Can not translate invalid month to quarter, [${month}]`
        );
    }
  }

  private static previousQuarter(quarter: Quarter): Quarter {
    switch (quarter) {
      case Quarter.First:
        return Quarter.Fourth;
      case Quarter.Second:
        return Quarter.First;
      case Quarter.Third:
        return Quarter.Second;
      case Quarter.Fourth:
        return Quarter.Third;
    }
  }

  constructor(public readonly quarter: Quarter, public readonly year: number) {}

  public get label(): string {
    return `Q${quarterToNumber(this.quarter)}-${this.year}`;
  }

  public previous(): QuarterOptions {
    return new QuarterOptions(
      QuarterOptions.previousQuarter(this.quarter),
      this.quarter === Quarter.First ? this.year - 1 : this.year
    );
  }
}
