import React from "react";
import styles from "./NoPartnerSelected.module.css";

export default function NoPartnerSelected() {
  return (
    <div className={styles.container}>
      <div className={styles.title}>No partner was selected.</div>
      <div className={styles.message}>
        This page requires selecting a partner using the dropdown
        <br />
        to view a specific partner's details.
      </div>
    </div>
  );
}
