import React, { createElement, useState } from "react";
import styles from "./NavItem.module.css";
import { ExpandMore, Lock } from "@mui/icons-material";
import classNames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import SubNavItems from "./SubNavItems";
import { useAnalytics } from "@btr-energy/analytics";
import { PartnerUserRole } from "../../graphql/generated/graphql";

export interface NavTab {
  icon: React.ComponentType;
  label: string;
  route: string;
  subItems?: SubItem[];
  allowedRoles: PartnerUserRole[];
}

export interface SubItem {
  label: string;
  route: string;
}

export default function NavItem({
  collapsed,
  enabled,
  navItem,
}: {
  collapsed: boolean;
  enabled: boolean;
  navItem: NavTab;
}) {
  const { icon, label, route, subItems } = navItem;
  const { pathname } = useLocation();
  const analytics = useAnalytics();
  const [showSubItems, setShowSubItems] = useState(
    pathname.startsWith(route) && pathname !== route && enabled
  );

  const toggleShowSubItems = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowSubItems(!showSubItems);
  };

  const hasSubItems = subItems !== undefined && subItems.length > 0;

  const collapseHoverStyle = collapsed ? {} : { display: "none" };

  return (
    <React.Fragment>
      <NavLink
        to={route}
        onClick={() => analytics.track({ name: `User clicked ${label}` })}
        className={({ isActive }) =>
          classNames(styles.item, { [styles.active]: isActive })
        }
      >
        <div
          className={classNames(styles.title, {
            [styles.titleDisabled]: !enabled,
          })}
        >
          {enabled ? createElement(icon) : <Lock />}
          {label}
        </div>
        {hasSubItems && enabled && (
          <button
            className={classNames(styles.expandButton, {
              [styles.expanded]: showSubItems,
            })}
            onClick={toggleShowSubItems}
          >
            <ExpandMore />
          </button>
        )}
        <div style={collapseHoverStyle} className={styles.hoverLabel}>
          {label}
        </div>
      </NavLink>
      {hasSubItems && showSubItems && <SubNavItems items={subItems} />}
    </React.Fragment>
  );
}
