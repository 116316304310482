import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../images/loading.json";
import chatbtrlogo from "../images/chatbtrlogosmall.svg";
import styles from "../styles/Loading.module.css";

export default function Loading({ size = 80 }) {
  const pxSize = size + "px";
  return (
    <div className={styles.container}>
      <img src={chatbtrlogo} alt="Chat BTR" />

      <Player
        style={{ width: pxSize, height: pxSize }}
        autoplay
        loop
        src={animationData}
      />
    </div>
  );
}
