import React, { useState } from "react";
import {
  Alert,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { convertDate, convertToUTCDate } from "../../../utils/convertDate";
import { useAuth0 } from "@auth0/auth0-react";
import BetterCardHeader from "../../../shared/components/BetterCardHeader";
import { SubmissionsPresenter } from "./SubmissionsPresenter";
import ClearableYearQuarterAutocomplete from "../../../shared/components/ClearableYearQuarterAutocomplete";
import {
  AccumulatedChargeQuery,
  YearQuarter,
} from "../../../graphql/generated/graphql";

interface DownloadingInProgressRows {
  [batchUrl: string]: boolean;
}

export default function Submissions({
  batches,
  downloader,
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone,
}: {
  batches: AccumulatedChargeQuery["chargingEventSummaries"];
  downloader: (params: { endpoint: string; token: string }) => Promise<void>;
  timeZone?: string;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const [downloadingInProgressRows, setDownloadingInProgress] =
    useState<DownloadingInProgressRows>({});
  const [failureMessage, setFailureMessage] = useState<string | null>(null);
  const handleFailureClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setFailureMessage(null);
  };

  const [chosenQuarter, setChosenQuarter] = useState<YearQuarter | null>(null);

  const downloadSubmission = async (endpoint: string) => {
    setDownloadingInProgressForRow(endpoint, true);
    try {
      await downloader({
        endpoint,
        token: await getAccessTokenSilently(),
      });
    } catch (e) {
      const msg = e instanceof Error ? e.message : "Something went wrong";
      setFailureMessage(msg);
    } finally {
      setDownloadingInProgressForRow(endpoint, false);
    }
  };

  function changeQuarter(e: any, newValue: YearQuarter | null): void {
    setChosenQuarter(newValue);
  }

  const setDownloadingInProgressForRow = (
    endpoint: string,
    status: boolean
  ) => {
    setDownloadingInProgress((previous) => ({
      ...previous,
      [endpoint]: status,
    }));
  };

  function batchesTable() {
    if (chosenQuarter === null) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Alert severity="info">No Quarter Selected </Alert>
          </Grid>
        </Grid>
      );
    }

    return (
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>OEM</TableCell>
              <TableCell>kWh</TableCell>
              <TableCell>Last Updated</TableCell>
              <TableCell>CARB Station List Date</TableCell>
              <TableCell align="right" />
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {new SubmissionsPresenter(batches, chosenQuarter).submissions.map(
              (batch) =>
                row(
                  downloadingInProgressRows[batch.downloadUrl] || false,
                  batch,
                  timeZone,
                  downloadSubmission
                )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <React.Fragment>
      <Snackbar
        data-testid="message-failure"
        open={!!failureMessage}
        autoHideDuration={6000}
        onClose={handleFailureClose}
      >
        <Alert elevation={6} variant="filled" severity="error">
          {failureMessage}
        </Alert>
      </Snackbar>
      <Card>
        <BetterCardHeader>
          <Grid container spacing={2}>
            <Grid item xs={9} style={{ display: "flex", alignItems: "center" }}>
              <Typography variant={"h5"}>Generate CARB Submissions</Typography>
            </Grid>
            <Grid item xs={3}>
              <ClearableYearQuarterAutocomplete
                changeQuarter={changeQuarter}
                size={"small"}
              />
            </Grid>
          </Grid>
        </BetterCardHeader>
        <CardContent>{batchesTable()}</CardContent>
      </Card>
    </React.Fragment>
  );
}

function spinner(testId: string) {
  return <CircularProgress data-testid={testId} size={25} />;
}

function row(
  downloading: boolean,
  batch: AccumulatedChargeQuery["chargingEventSummaries"][number],
  timeZone: string,
  downloadSubmission: (endpoint: string) => Promise<void>
) {
  return (
    <TableRow key={batch.oem.id}>
      <TableCell>{batch.oem.shortName}</TableCell>
      <TableCell>{(batch.wattHours / 1_000).toLocaleString()}</TableCell>
      <TableCell>{convertDate(batch.updatedAt, timeZone)}</TableCell>
      <TableCell>
        {batch.carbDateForStationsUsedToFilter
          ? convertToUTCDate(batch.carbDateForStationsUsedToFilter)
          : "N/A"}
      </TableCell>
      <TableCell align="right">
        {downloading ? (
          spinner("download-submission-spinner")
        ) : (
          <Tooltip title="Download Submission">
            <IconButton
              data-testid="download-submission"
              onClick={() => downloadSubmission(batch.downloadUrl)}
            >
              <Icon>get_app</Icon>
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
}
