import { Autocomplete, TextField } from "@mui/material";
import React, { CSSProperties } from "react";
import { useQuery } from "@apollo/client";
import { Oem } from "../../graphql/generated/graphql";
import { graphql } from "../../graphql/generated";

export const OEMS_FILTER_QUERY = graphql(`
  query oemsFilter {
    oems {
      id
      shortName
      isGeolocated
      fullName
      fein
    }
  }
`);

export const ALL_OEM = {
  id: 0,
  shortName: "All Partners",
  isGeolocated: false,
  fullName: "All Partners",
  fein: "999999999",
};

export default function OemFilter({
  changeOem,
  style,
  size = "medium",
  includeAllPartnersOption = true,
  overrideDefaultValue = null,
}: {
  changeOem: (e: any, newValue: Oem) => void;
  style?: CSSProperties;
  size?: "small" | "medium";
  includeAllPartnersOption?: boolean;
  overrideDefaultValue?: Oem | null;
}) {
  const { error, data, loading } = useQuery(OEMS_FILTER_QUERY);

  if (loading) {
    return <div />;
  }

  if (error || !data) {
    return <div />;
  }

  const options = includeAllPartnersOption
    ? [ALL_OEM, ...data.oems]
    : data?.oems;

  return (
    <Autocomplete
      data-testid="oem-filter-selector"
      autoSelect
      id="oem-select-box"
      options={options}
      size={size}
      disableClearable
      isOptionEqualToValue={(option, value) => option.id === value.id}
      defaultValue={overrideDefaultValue ? overrideDefaultValue : options[0]}
      getOptionLabel={(option) => option.shortName}
      style={style}
      renderInput={(params) => <TextField {...params} variant="outlined" />}
      onChange={changeOem}
    />
  );
}
