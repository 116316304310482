import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Icon,
  Snackbar,
} from "@mui/material";
import styles from "./GraphQLUpload.module.css";

type Upload = (files: File[]) => Promise<number | void>;

export default function GraphQLUpload({
  accept,
  elementName,
  loading,
  upload,
  postUpload,
  multiFileUpload = true,
  testId = "file-upload-input",
  successMessage = null,
  postUploadVerb = "Added",
  requiredFields = [],
}: {
  accept?: string[];
  elementName: string;
  loading: boolean;
  upload: Upload;
  postUpload?: () => void;
  multiFileUpload?: boolean;
  testId?: string;
  successMessage?: string | null;
  postUploadVerb?: string;
  requiredFields?: any[];
}) {
  const [successCount, setSuccessCount] = useState<number | null>(null);
  const [displayFailure, setDisplayFailure] = useState<string | null>(null);

  const uploadFiles = useCallback(
    (files: File[]) => {
      const missingRequiredFields = requiredFields.some((field) => !field);
      if (missingRequiredFields) {
        setDisplayFailure("Please select a partner for the upload");
        return;
      }

      const anyFileTooBig = files.some((file) => file.size > 15000000);
      if (anyFileTooBig) {
        setDisplayFailure("File Upload Failure: File size too big");
        return;
      }

      upload(files)
        .then((added) => {
          setSuccessCount(added === undefined ? null : added);
          if (postUpload) {
            postUpload();
          }
        })
        .catch((error) => {
          setDisplayFailure(
            "File Upload Failure" + (error.message ? ": " + error.message : "")
          );
        });
    },
    [upload, postUpload, requiredFields]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    onDropAccepted: uploadFiles,
    multiple: multiFileUpload,
  });

  const handleSuccessClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessCount(null);
  };

  const handleFailureClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setDisplayFailure(null);
  };

  const displaySuccess = successCount !== null;

  return (
    <React.Fragment>
      <Snackbar
        data-testid="message-success"
        open={displaySuccess}
        autoHideDuration={6000}
        onClose={handleSuccessClose}
      >
        <Alert elevation={6} variant="filled" severity="success">
          {successMessage
            ? successMessage
            : `File Uploaded Successfully, ${(
                successCount || 0
              ).toLocaleString()} 
          ${elementName} ${postUploadVerb}`}
        </Alert>
      </Snackbar>

      <Snackbar
        data-testid="message-failure"
        open={displayFailure != null}
        autoHideDuration={6000}
        onClose={handleFailureClose}
      >
        <Alert elevation={6} variant="filled" severity="error">
          {displayFailure}
        </Alert>
      </Snackbar>

      <div data-testid="file-upload-dropzone" {...getRootProps()}>
        {loading ? (
          <CircularProgress data-testid="loading" />
        ) : (
          <Box
            border={2}
            bgcolor={isDragActive ? "#F2F4F7" : "inherit"}
            className={styles.dragBox}
          >
            <input {...getInputProps()} data-testid={testId} />
            <span className={styles.dragAndDropText}>
              Drag and Drop {elementName}
            </span>{" "}
            or{" "}
            <Button
              variant="outlined"
              color="primary"
              component="label"
              endIcon={<Icon>publish</Icon>}
            >
              Upload
            </Button>
          </Box>
        )}
      </div>
    </React.Fragment>
  );
}
