import {
  AccumulatedChargeQuery,
  YearQuarter,
} from "../../../graphql/generated/graphql";

export class SubmissionsPresenter {
  submissions: AccumulatedChargeQuery["chargingEventSummaries"];
  constructor(
    submissions: AccumulatedChargeQuery["chargingEventSummaries"],
    chosenYearQuarter: YearQuarter
  ) {
    this.submissions = submissions.filter(
      (s) =>
        s.yearQuarter.year === chosenYearQuarter.year &&
        s.yearQuarter.quarter === chosenYearQuarter.quarter
    );
  }
}
