import React, { ReactNode, useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import styles from "../styles/Tooltip.module.css";

interface ToolTipProps {
  children: ReactNode;
  content: ReactNode;
  disableHoverListener?: boolean;
}

export default function ToolTip({
  children,
  content,
  disableHoverListener = false,
}: ToolTipProps) {
  const [showToolTip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (showToolTip) {
      const timer = setTimeout(() => setShowTooltip(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [showToolTip]);

  const handleClick = () => {
    if (disableHoverListener) {
      setShowTooltip(true);
    }
  };

  return (
    <Tooltip
      title={content}
      arrow
      placement="bottom"
      classes={{
        tooltip: styles.tooltip,
        arrow: styles.arrow,
      }}
      onClick={handleClick}
      open={disableHoverListener ? showToolTip : undefined}
      disableHoverListener={disableHoverListener}
    >
      <span>{children}</span>
    </Tooltip>
  );
}
