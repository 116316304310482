import { Quarter, YearQuarter } from "../graphql/generated/graphql";

export function quarterToNumber(quarter: Quarter): number {
  switch (quarter) {
    case Quarter.First:
      return 1;
    case Quarter.Second:
      return 2;
    case Quarter.Third:
      return 3;
    case Quarter.Fourth:
      return 4;
  }
}

export function stringifyQuarter(yearQuarter: YearQuarter) {
  return `Q${quarterToNumber(yearQuarter.quarter)} ${yearQuarter.year}`;
}
