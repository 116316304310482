import React, { useEffect } from "react";
import { Tab, Tabs, Toolbar } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import styles from "./Navigation.module.css";
import { createTabTitle } from "../utils/createTabTitle";

const links = [
  {
    to: "/registrations",
    label: "Registrations",
  },
  {
    to: "/charging",
    label: "Charge Data",
  },
  {
    to: "/charging-stations",
    label: "Charging Stations",
  },
];

export default function Navigation() {
  const routerLocation = useLocation();
  const currentIndex = findIndexWithDefault(
    links,
    (link) => link.to === routerLocation.pathname,
    0
  );

  useEffect(() => {
    document.title = createTabTitle(links[currentIndex].label);
  }, [currentIndex]);

  return (
    <Toolbar className={styles.root} component="nav">
      <Tabs indicatorColor="primary" value={currentIndex}>
        {links.map((link) => (
          <Tab
            key={link.to}
            component={Link}
            to={link.to}
            label={link.label}
            className={styles.tab}
          />
        ))}
      </Tabs>
    </Toolbar>
  );
}

function findIndexWithDefault<T>(
  items: Array<T>,
  predicate: (value: T, index: number, obj: T[]) => boolean,
  defaultValue: number
): number {
  const value = items.findIndex(predicate);
  return value === -1 ? defaultValue : value;
}
