import React, { createContext, ReactNode, useContext } from "react";
import { Quarter } from "../graphql/generated/graphql";
import { QuarterOptions } from "./QuarterOptions";

type QuarterOptionsContextType = {
  options: QuarterOptions[];
  defaultOption: QuarterOptions;
};
const QuarterOptionsContext = createContext<QuarterOptionsContextType>({
  options: [],
  defaultOption: new QuarterOptions(Quarter.First, 1),
});
export const useQuarterOptions = () => useContext(QuarterOptionsContext);

const recentQuarters = (date: Date, numOfOptions: number): QuarterOptions[] => {
  const currentQuarter = QuarterOptions.fromDate(date);
  const recent = [currentQuarter];
  for (let i = numOfOptions - 1; i > 0; i--) {
    recent.push(recent[recent.length - 1].previous());
  }
  return recent;
};

export const QuarterOptionsProvider = ({
  children,
  date,
}: {
  children: ReactNode;
  date: Date;
}) => {
  const options = recentQuarters(date, 4);
  return (
    <QuarterOptionsContext.Provider
      value={{
        options,
        defaultOption: options[0],
      }}
    >
      {children}
    </QuarterOptionsContext.Provider>
  );
};
