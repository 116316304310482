import React, { useState } from "react";
import {
  Alert,
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemText,
  Snackbar,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@apollo/client";
import { downloadWithAuth } from "../../../../utils/downloadWithAuth";
import { graphql } from "../../../../graphql/generated";
import { RegistrationDashboardQuery } from "../../../../graphql/generated/graphql";

export const CONFIRM_DOWNLOAD = graphql(`
  mutation confirmDownload {
    markRegistrationBatchesAsDownloaded {
      flow {
        id
        step
      }
    }
  }
`);

export const CREATE_BATCHES = graphql(`
  mutation createBatches($oemIds: [Int!]!) {
    createRegistrationBatches(oemIds: $oemIds) {
      flow {
        id
        step
      }
      registrationsInUnsubmittedBatches
    }
  }
`);

export default function Export({
  postExport,
  oemUnsubmitted,
}: {
  postExport: () => void;
  oemUnsubmitted: RegistrationDashboardQuery["unsubmittedCarbRegistrationsPerOem"];
}) {
  const [createBatches] = useMutation(CREATE_BATCHES);
  const [confirmDownload] = useMutation(CONFIRM_DOWNLOAD);
  const { getAccessTokenSilently } = useAuth0();
  const [selectedBatches, setSelectedBatches] = useState<number[]>([]);
  const [failureMessage, setFailureMessage] = useState<string | null>(null);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const handleFailureClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setFailureMessage(null);
  };

  const updateSelectedBatches = (id: number, add: boolean) => {
    if (!add) {
      setSelectedBatches(selectedBatches.filter((entry) => entry !== id));
      return;
    }

    setSelectedBatches((oldOnes) => {
      return [...oldOnes, id];
    });
  };

  const downloadUnsubmitted = async () => {
    setIsDownloading(true);
    const batchResult = await createBatches({
      variables: {
        oemIds: selectedBatches,
      },
    });
    if (
      batchResult.data?.createRegistrationBatches
        .registrationsInUnsubmittedBatches === 0
    ) {
      setFailureMessage(
        "Can not create submission, no unsubmitted registrations"
      );
      setIsDownloading(false);
      return;
    }

    await downloadWithAuth({
      filename: "batches-for-carb.zip",
      endpoint: "internal/vehicle-registration-batches/undownloaded",
      token: await getAccessTokenSilently(),
    });
    await confirmDownload();

    postExport();
    setIsDownloading(false);
  };

  return (
    <React.Fragment>
      <Snackbar
        data-testid="message-failure"
        open={!!failureMessage}
        autoHideDuration={6000}
        onClose={handleFailureClose}
      >
        <Alert elevation={6} variant="filled" severity="error">
          {failureMessage}
        </Alert>
      </Snackbar>
      <Grid container>
        <Grid item xs={3}>
          <List>
            {oemUnsubmitted
              .filter((batch) => batch.unsubmittedCarb > 0)
              .map((batch, i) => (
                <ListItem
                  disableGutters
                  alignItems="flex-start"
                  key={`oem-unsubmitted-${i}`}
                >
                  <Checkbox
                    color={"primary"}
                    data-testid={`${batch.oem.shortName}-batch-checkbox`}
                    onChange={(e, checked) => {
                      updateSelectedBatches(batch.oem.id, checked);
                    }}
                  />
                  <ListItemText
                    primary={batch.oem.shortName}
                    secondary={`${batch.unsubmittedCarb} unsubmitted registrations`}
                  />
                </ListItem>
              ))}
          </List>
          <Button
            data-testid="download-button"
            variant="contained"
            disableElevation
            disabled={selectedBatches.length === 0 || isDownloading}
            color="primary"
            onClick={downloadUnsubmitted}
          >
            Export .zip
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
