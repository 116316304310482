import React, { useEffect, useRef, useState } from "react";
import ChatContext from "./ChatContext";
import circlearrows from "../images/icons/circle-arrows.svg";
import styles from "../styles/InputContainer.module.css";
import Button from "./Button";

interface InputContainerProps {
  handleSubmit: (value: string) => void;
  context: string;
  handleUpdateContext: (newContext: string) => void;
  contextCanBeUpdated: boolean;
  isDisabled: boolean;
}

export default function InputContainer({
  handleSubmit,
  context,
  handleUpdateContext,
  contextCanBeUpdated,
  isDisabled,
}: InputContainerProps) {
  const [value, setValue] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = "auto";

      const computedStyle = window.getComputedStyle(textarea);
      const lineHeight = parseInt(computedStyle.lineHeight);
      const currentRows = Math.floor(textarea.scrollHeight / lineHeight);

      if (currentRows <= 4) {
        textarea.style.height = `${textarea.scrollHeight}px`;
      } else {
        textarea.style.height = `${lineHeight * 4}px`;
      }
    }
  }, [value]);

  const submit = () => {
    handleSubmit(value);
    setValue("");
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      submit();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    setValue(event.target.value);

  return (
    <div className={styles.inputContainer}>
      <div className={styles.inputForm}>
        <textarea
          ref={textareaRef}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          tabIndex={0}
          rows={1}
          placeholder="How can I help?"
          className={styles.input}
          disabled={isDisabled}
        />

        <div className={styles.inputActions}>
          <ChatContext
            context={context}
            setContext={handleUpdateContext}
            canEdit={contextCanBeUpdated}
          />

          <Button onClick={submit} disabled={isDisabled}>
            <img src={circlearrows} alt="Generate SQL" /> Generate SQL
          </Button>
        </div>
      </div>
    </div>
  );
}
