import React from "react";
import styles from "./WAProgramNoParticipation.module.css";
import WashingtonIcon from "../../PartnerDashboards/shared/logos/WashingtonIcon";

export default function WAProgramNoParticipation() {
  return (
    <div className={styles.container}>
      <WashingtonIcon size={52} />
      <div className={styles.title}>
        Your organization doesn't currently participate in the WA CFS program.
      </div>
      <div className={styles.message}>
        Please contact your BTR Program Manager for participation.
      </div>
    </div>
  );
}
