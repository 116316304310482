import React, { ChangeEvent, FormEvent, useState } from "react";
import { ApolloQueryResult, useMutation } from "@apollo/client";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField,
} from "@mui/material";
import { graphql } from "../../../../graphql/generated";
import {
  RegistrationDashboardQuery,
  UnsubmittedBatch,
} from "../../../../graphql/generated/graphql";

export const CONFIRM_SUBMISSION = graphql(`
  mutation confirmRegistrationBatchSubmission(
    $confirmations: [BatchSubmissionConfirmation!]!
  ) {
    confirmRegistrationBatchSubmission(confirmations: $confirmations) {
      flow {
        id
        step
      }
      submittedBatches {
        id
        submittedBatches {
          id
          approvedCount
          name
          pendingCount
          registrationUploadNumber
          rejectedCount
          submittedAt
          vehicleCount
        }
      }
    }
  }
`);

export const BACK_TO_DOWNLOAD = graphql(`
  mutation resetUnsubmittedBatches {
    resetUnsubmittedBatches {
      flow {
        id
        step
      }
    }
  }
`);

interface Confirmations {
  [batchId: string]: string;
}

export default function Confirm({
  unsubmittedBatches,
  refetch,
}: {
  unsubmittedBatches: UnsubmittedBatch[];
  refetch: () => Promise<ApolloQueryResult<RegistrationDashboardQuery>>;
}) {
  const [confirm] = useMutation(CONFIRM_SUBMISSION);
  const [confirmations, setConfirmations] = useState<Confirmations>({});
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<
    { batchId: number; error: boolean }[]
  >(
    unsubmittedBatches.map((batch) => ({
      batchId: batch.id,
      error: false,
    }))
  );
  const handleConfirmationChange = (
    { target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    batchId: number
  ) => {
    setConfirmations((confirmations) => ({
      ...confirmations,
      [batchId]: target.value,
    }));
  };
  const onSubmit = async (event: FormEvent) => {
    setIsConfirming(true);
    event.preventDefault();
    await confirm({
      variables: {
        confirmations: Object.entries(confirmations).map(
          ([batchId, registrationUploadNumber]) => ({
            batchId: Number(batchId),
            registrationUploadNumber: `RU-${registrationUploadNumber.trim()}`,
          })
        ),
      },
    });

    await refetch();
    setIsConfirming(false);
  };

  const [back] = useMutation(BACK_TO_DOWNLOAD);
  const backToDownload = async () => {
    await back();
  };

  const ruNumberLabelText = (batchId: number) => {
    if (!errorState.find((e) => e.batchId === batchId)?.error) {
      return "";
    }
    if (!confirmations[batchId]) return "RU Number cannot be blank";
    if (formIsBroken(batchId)) return "RU Number must be in format: RU-#####";
  };

  const formIsBroken = (batchId: number) =>
    !new RegExp(/^\s*\d{5}\s*$/i).test(confirmations[batchId]);

  const revalidateErrors = () => {
    setErrorState(
      errorState.map((e) => {
        return {
          batchId: e.batchId,
          error: formIsBroken(e.batchId),
        };
      })
    );
  };

  const buttonShouldBeDisabled = (): boolean => {
    const brokenBatch = unsubmittedBatches.find((batch) =>
      formIsBroken(batch.id)
    );
    return !!brokenBatch;
  };

  return (
    <form onSubmit={onSubmit}>
      {unsubmittedBatches.map((batch) => (
        <Box key={batch.id} pb={1}>
          <FormControl variant="outlined">
            <TextField
              value={confirmations[batch.id] || ""}
              error={
                !!errorState.find((e) => {
                  return e.batchId === batch.id && e.error;
                })
              }
              variant="outlined"
              onBlur={revalidateErrors}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ marginTop: "2px" }} position="start">
                    RU-
                  </InputAdornment>
                ),
              }}
              label={ruNumberLabelText(batch.id)}
              onChange={(event) => handleConfirmationChange(event, batch.id)}
            />
            <FormHelperText id="component-helper-text">
              {batch.name} ({batch.vehicleCount} vehicles)
            </FormHelperText>
          </FormControl>
        </Box>
      ))}
      <Box pt={3}>
        <Box component="span" mr={1}>
          <Button type="reset" onClick={backToDownload}>
            Back
          </Button>
        </Box>

        <Button
          disabled={buttonShouldBeDisabled() || isConfirming}
          variant="contained"
          disableElevation
          color="primary"
          type="submit"
        >
          {isConfirming ? "Confirming..." : "Confirm"}
        </Button>
      </Box>
    </form>
  );
}
