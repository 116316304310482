import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import ErrorIndicator from "../../../../shared/components/ErrorIndicator";
import { MoreHorizTwoTone } from "@mui/icons-material";
import { useDeleteModalContext } from "../../../../shared/components/modals/DeleteModal";
import styles from "./InvitedUserList.module.css";
import OptionMenu from "../../../../shared/components/OptionMenu";
import ToastBar from "../../../../shared/components/ToastBar";
import LottieLoadingIndicator from "../../../../shared/components/LottieLoadingIndicator";
import {
  PartnerUserRole,
  PendingInvitation,
} from "../../../../graphql/generated/graphql";
import { graphql } from "../../../../graphql/generated";
import { INVITE_USER, OEM_INVITED_USERS } from "./InviteUser";

export const DELETE_INVITATION = graphql(`
  mutation deleteInvitation($oemShortname: String!, $invitationId: String!) {
    deletePendingInvitation(
      oemShortname: $oemShortname
      invitationId: $invitationId
    )
  }
`);

export default function InvitedUserList({
  isAdmin,
  oemShortname,
}: {
  isAdmin: boolean;
  oemShortname: string;
}) {
  const { data, error, loading, refetch } = useQuery(OEM_INVITED_USERS, {
    variables: { oemShortname: oemShortname },
  });
  const { showModal } = useDeleteModalContext();
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showFailure, setShowFailure] = useState(false);
  const [inviteNewUser] = useMutation(INVITE_USER);
  const [deleteInvitationMutation] = useMutation(DELETE_INVITATION);

  const [invitationBeingManaged, setInvitationBeingManaged] = useState<
    PendingInvitation | undefined
  >(undefined);

  if (loading) {
    return LottieLoadingIndicator();
  }

  if (error || !data) {
    return ErrorIndicator();
  }

  const resendInvitation = async (userEmail: string, role: PartnerUserRole) => {
    const variables = { oemShortname, userEmail, role };

    try {
      await inviteNewUser({ variables });
    } catch {
      return setShowFailure(true);
    }

    setSuccessMessage(`Invitation has been sent to ${userEmail}.`);
    setShowSuccess(true);
    await refetch();
  };

  const deleteInvitation = async (invitationId: string) => {
    try {
      await deleteInvitationMutation({
        variables: {
          oemShortname,
          invitationId,
        },
      });
    } catch {
      return setShowFailure(true);
    }

    setSuccessMessage(`Invitation deleted successfully.`);
    setShowSuccess(true);
    await refetch();
  };

  const handleClose = () => setInvitationBeingManaged(undefined);

  const noPendingUsers = data.invitedUserList.length === 0;
  if (noPendingUsers) {
    return (
      <div className={styles.noPending}>
        You have <strong>0</strong> outgoing invites to users.
      </div>
    );
  }

  return (
    <React.Fragment>
      <table className={styles.pendingTable}>
        <thead>
          <tr>
            <th>New User Email</th>
            <th>Invited By</th>
            <th>Date Sent</th>
            <th>Invitation Expires</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {data.invitedUserList.map((invite, index) => (
            <tr key={index}>
              <td>{invite.invitee.email}</td>
              <td>{invite.inviter.name}</td>
              <td>{new Date(invite.createdAt).toLocaleString()}</td>
              <td>{new Date(invite.expiresAt).toLocaleString()}</td>
              <td>
                {isAdmin && (
                  <div className={styles.menuContainer}>
                    <button
                      data-testid={`menu-select-${invite.id}`}
                      className={styles.inviteOptions}
                      onClick={() => setInvitationBeingManaged(invite)}
                    >
                      <MoreHorizTwoTone />
                    </button>

                    {invitationBeingManaged === invite && (
                      <OptionMenu
                        toggleOpen={handleClose}
                        className={styles.menu}
                      >
                        <button
                          data-testid={`re-invite-${invite.id}`}
                          onClick={async () => {
                            handleClose();
                            await resendInvitation(
                              invite.invitee.email,
                              invite.inviteRole
                            );
                          }}
                        >
                          Resend Invitation
                        </button>
                        <button
                          data-testid={`delete-invitation-${invite.id}`}
                          className={styles.deleteButton}
                          onClick={() => {
                            showModal(
                              invite.id,
                              `The invitation for ${invite.invitee.email} will be deleted.`,
                              () => deleteInvitation
                            );
                            handleClose();
                          }}
                        >
                          Delete Invitation
                        </button>
                      </OptionMenu>
                    )}
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <ToastBar
        type="success"
        message={successMessage}
        open={showSuccess}
        onClose={() => setShowSuccess(false)}
      />

      <ToastBar
        type="error"
        message="Failed to modify user invite. Please contact support."
        open={showFailure}
        onClose={() => setShowFailure(false)}
      />
    </React.Fragment>
  );
}
