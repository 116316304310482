export default function CaliforniaIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" stroke="#07174F" strokeWidth="2" />
      <path
        d="M11.508 10.9786H10.199C10.1751 10.7901 10.1263 10.6226 10.0526 10.4762C9.97885 10.3276 9.88421 10.2012 9.76865 10.097C9.65309 9.99271 9.5196 9.91286 9.36818 9.85741C9.21875 9.80196 9.05636 9.77424 8.88103 9.77424C8.56424 9.77424 8.28829 9.86185 8.05318 10.0371C7.81808 10.2101 7.63577 10.4629 7.50627 10.7956C7.37676 11.1261 7.31201 11.5276 7.31201 12C7.31201 12.4857 7.37676 12.8939 7.50627 13.2243C7.63776 13.5548 7.82107 13.8043 8.05617 13.9729C8.29128 14.1415 8.56324 14.2258 8.87207 14.2258C9.04541 14.2258 9.2058 14.2003 9.35323 14.1492C9.50266 14.0982 9.63516 14.0239 9.75072 13.9263C9.86628 13.8265 9.96192 13.7056 10.0376 13.5637C10.1153 13.4217 10.1691 13.2598 10.199 13.0779L11.508 13.0846C11.4742 13.3973 11.3895 13.699 11.254 13.9895C11.1205 14.2779 10.9402 14.5363 10.7131 14.7647C10.4879 14.991 10.2189 15.1706 9.90613 15.3037C9.59531 15.4346 9.24365 15.5 8.85115 15.5C8.30522 15.5 7.81708 15.3625 7.38672 15.0875C6.95835 14.8124 6.61964 14.4143 6.37059 13.8931C6.12353 13.3718 6 12.7408 6 12C6 11.257 6.12552 10.6248 6.37657 10.1036C6.62761 9.58238 6.96831 9.18536 7.39868 8.91255C7.82904 8.63752 8.31319 8.5 8.85115 8.5C9.2058 8.5 9.53454 8.55545 9.83739 8.66635C10.1422 8.77725 10.4122 8.93916 10.6473 9.15209C10.8824 9.3628 11.0737 9.6212 11.2211 9.92728C11.3706 10.2334 11.4662 10.5838 11.508 10.9786Z"
        fill="#07174F"
      />
      <path
        d="M13.4961 15.4068H12.1094L14.2224 8.59316H15.89L18 15.4068H16.6133L15.0801 10.1502H15.0323L13.4961 15.4068ZM13.4095 12.7286H16.685V13.8531H13.4095V12.7286Z"
        fill="#07174F"
      />
    </svg>
  );
}
