import React from "react";

export default function PartnerLogoOrEmpty({
  logoUrl,
}: {
  logoUrl: string | null | undefined;
}) {
  if (!logoUrl) {
    return <div />;
  }

  return (
    <img
      src={logoUrl}
      alt={"Partner Logo"}
      style={{ width: "56px", height: "56px" }}
    />
  );
}
