import { useQuery } from "@apollo/client";
import ErrorIndicator from "../../../../shared/components/ErrorIndicator";
import LottieLoadingIndicator from "../../../../shared/components/LottieLoadingIndicator";
import { graphql } from "../../../../graphql/generated";
import styles from "./LoginActivity.module.css";
import { createTabTitle } from "../../../../utils/createTabTitle";
import PasswordResetButton from "../../PasswordResetButton";
import Pagination from "../../../../shared/components/Pagination";
import { useState } from "react";

export const LOGIN_ACTIVITY = graphql(`
  query userLoginHistory {
    userLoginHistory {
      date
      ip
      userAgent
      country
      city
    }
  }
`);

export default function LoginActivity() {
  const { data, error, loading } = useQuery(LOGIN_ACTIVITY);
  const EVENTS_PER_PAGE = 11;
  const [page, setPage] = useState(1);

  document.title = createTabTitle("Login Activity");

  const formatTime = (timestamp: string) => {
    const date = new Date(timestamp);

    const formattedDate = new Intl.DateTimeFormat("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    }).format(date);

    const formattedTime = new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(date);

    return `${formattedDate} | ${formattedTime}`;
  };

  if (loading) {
    return LottieLoadingIndicator();
  }

  if (error || !data) {
    return ErrorIndicator();
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>Activity Log</div>
      <div className={styles.description}>
        Login activity from the last 28 days.
        <br />
        <br />
        {
          "If there’s recent activity you don’t recognize, someone else might have your password. "
        }
        <PasswordResetButton>
          <div className={styles.changePassword}>Change your password</div>
        </PasswordResetButton>
        {" to protect your account."}
      </div>
      <hr />
      <table>
        <thead>
          <tr>
            <th>Date & Time</th>
            <th>Location</th>
            <th>IP Address</th>
            <th>User Agent</th>
          </tr>
        </thead>
        <tbody>
          {data.userLoginHistory
            .slice((page - 1) * EVENTS_PER_PAGE, page * EVENTS_PER_PAGE)
            .map((event, index) => (
              <tr key={index}>
                <td>{formatTime(event.date)}</td>
                <td>{event.city.concat(", ", event.country)}</td>
                <td>{event.ip}</td>
                <td>{event.userAgent}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className={styles.paginationContainer}>
        <Pagination
          items={data.userLoginHistory}
          countPerPage={EVENTS_PER_PAGE}
          currentPage={page}
          setPage={setPage}
        />
      </div>
    </div>
  );
}
