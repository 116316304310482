export function convertDate(dateString: string, timeZone: string): string {
  return Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone,
  }).format(new Date(dateString));
}

export function convertToUTCDate(dateString: string): string {
  return convertDate(dateString, "UTC");
}
