import { useQuery } from "@apollo/client";
import ErrorIndicator from "../../../../shared/components/ErrorIndicator";
import React, { useEffect, useState } from "react";
import { displayFileSize } from "../../../../utils/formatters";
import { parseISOLocalDate } from "../../../../utils/parseISOLocalDate";
import { DownloadPartnerDocumentButton } from "./DownloadPartnerDocumentButton";
import styles from "./Resources.module.css";
import DocumentIcon from "../../../../shared/components/DocumentIcon";
import LottieLoadingIndicator from "../../../../shared/components/LottieLoadingIndicator";
import { graphql } from "../../../../graphql/generated";
import { ALL_PARTNER } from "../../../../shared/components/PartnerFilter";
import NoPartnerSelected from "../../../../shared/components/NoPartnerSelected";
import {
  PartnerUserRole,
  UserPartnerQuery,
} from "../../../../graphql/generated/graphql";
import { useRoles } from "../../../../App/RolesContext";
import { DeleteDocumentButton } from "./DeleteDocumentButton";
import { DocumentUpload } from "./DocumentUpload";

export const PARTNER_DOCUMENTS = graphql(`
  query partnerDocuments($oemShortname: String!) {
    partnerDocuments(oemShortname: $oemShortname) {
      fileName
      fileSize
      dateAdded
      extension
    }
  }
`);

export default function Resources({
  partner,
}: {
  partner: UserPartnerQuery["currentUserPartner"];
}) {
  const { data, loading, error, refetch } = useQuery(PARTNER_DOCUMENTS, {
    skip: partner === ALL_PARTNER,
    variables: { oemShortname: partner.shortName },
  });

  const { roles } = useRoles();

  const [isInternal, setIsInternal] = useState(false);

  useEffect(() => {
    const hasInternalRole = roles.some((r) => r === PartnerUserRole.Internal);
    setIsInternal(hasInternalRole);
  }, []);

  if (error) {
    return ErrorIndicator();
  }

  if (partner === ALL_PARTNER) {
    return <NoPartnerSelected />;
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Resources</h2>

      {isInternal && (
        <DocumentUpload
          partnerName={partner.shortName}
          refetchDocuments={refetch}
        />
      )}

      {loading ? (
        LottieLoadingIndicator()
      ) : data?.partnerDocuments.length === 0 ? (
        <div className={styles.empty}>No resources available at this time.</div>
      ) : (
        <table className={styles.documentTable}>
          <thead>
            <tr>
              <th>Type</th>
              <th>Document Name</th>
              <th>File Size</th>
              <th>Upload Date</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data?.partnerDocuments.map((document) => (
              <tr key={document.fileName}>
                <td>
                  <DocumentIcon fileName={document.fileName} />
                </td>

                <td>{document.fileName}</td>

                <td>{displayFileSize(document.fileSize)}</td>

                <td>
                  {parseISOLocalDate(document.dateAdded).toLocaleDateString()}
                </td>

                <td>
                  <div className={styles.buttons}>
                    {isInternal && (
                      <DeleteDocumentButton
                        fileName={document.fileName}
                        partnerName={partner.shortName}
                        refetchDocuments={refetch}
                      />
                    )}

                    <DownloadPartnerDocumentButton
                      fileName={document.fileName}
                      partnerName={partner.shortName}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
