import React from "react";

import SavedQueries from "./SavedQueries";
import btrLogo from "../images/logo.png";
import chatBtrLogo from "../images/chatbtrlogofull.png";

import styles from "../styles/Sidenav.module.css";
import { Query } from "../SavedQueriesContext";

export default function Sidenav({
  toggleChatBtr,
  onLoadQuery,
}: {
  toggleChatBtr: (() => void) | undefined;
  onLoadQuery: (query: Query) => void;
}) {
  return (
    <div className={styles.nav}>
      <div className={styles.top}>
        <span>
          <img
            src={chatBtrLogo}
            alt="Chat BTR"
            className={styles.chatBtrLogo}
          />
        </span>

        <SavedQueries onLoadQuery={onLoadQuery} />
      </div>

      <button className={styles.poweredBy} onClick={toggleChatBtr}>
        <span>Powered by</span>
        <img src={btrLogo} alt="BTR Energy" className={styles.btrLogo} />
      </button>
    </div>
  );
}
