import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useQuarterOptions } from "../../App/QuarterOptionsContext";
import { stringifyQuarter } from "../../utils/quarterHelpers";
import { QuarterOptions } from "../../App/QuarterOptions";
import { YearQuarter } from "../../graphql/generated/graphql";

export default function YearQuarterAutocomplete({
  changeQuarter,
  size = "medium",
  testId = "quarter-filter-autocomplete",
  disabled = false,
}: {
  changeQuarter: (e: any, newValue: YearQuarter) => void;
  size?: "small" | "medium";
  testId?: string;
  disabled?: boolean;
}) {
  const { options: previousFourQuarters } = useQuarterOptions();

  return (
    <Autocomplete
      disabled={disabled}
      autoSelect
      id="combo-box-quarter"
      data-testid={testId}
      options={previousFourQuarters}
      defaultValue={previousFourQuarters[0]}
      size={size}
      disableClearable
      getOptionLabel={(option) => stringifyQuarter(option)}
      renderInput={(params) => (
        <TextField {...params} label="Quarter" variant="outlined" />
      )}
      onChange={changeQuarter}
      isOptionEqualToValue={(option: QuarterOptions, value: QuarterOptions) =>
        value.quarter === option.quarter && value.year === option.year
      }
    />
  );
}
