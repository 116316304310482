import React, { useRef } from "react";
import { Modal } from "@mui/material";
import Tooltip from "./Tooltip";
import EditNoteIcon from "@mui/icons-material/EditNote";
import styles from "../styles/ChatContext.module.css";
import { CloseRounded, InfoOutlined } from "@mui/icons-material";
import classNames from "classnames";
import Button from "./Button";

interface ChatContextProps {
  context: string;
  setContext: (newContext: string) => void;
  canEdit: boolean;
}

export default function ChatContext({
  context,
  setContext,
  canEdit,
}: ChatContextProps) {
  const editRef = useRef<HTMLDivElement>(null);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSave = () => {
    if (!editRef?.current?.textContent) return;

    setContext(editRef.current.textContent);
  };

  return (
    <div className={styles.container}>
      <Tooltip content="Edit or view the initial chat prompt">
        <button
          className={classNames(styles.open, {
            [styles.noEdit]: !canEdit,
          })}
          onClick={handleOpen}
        >
          <EditNoteIcon />
        </button>
      </Tooltip>

      <Modal open={open} onClose={handleClose}>
        <div className={styles.modal}>
          <div className={styles.header}>
            {canEdit && (
              <div className={styles.info}>
                <InfoOutlined fontSize="inherit" /> Click below to edit. This
                cannot be changed after the chat has started.
              </div>
            )}

            {!canEdit && (
              <div className={styles.warning}>
                <InfoOutlined fontSize="inherit" /> This cannot be edited, as
                the chat has already started.
              </div>
            )}

            <button className={styles.close} onClick={handleClose}>
              <CloseRounded />
            </button>
          </div>

          <div
            ref={editRef}
            className={styles.text}
            suppressContentEditableWarning={true}
            contentEditable={canEdit}
          >
            {context}
          </div>

          {canEdit && (
            <div className={styles.buttons}>
              <Button onClick={handleSave}>Save</Button>

              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
