import React from "react";

export default function DocumentIcon({ fileName }: { fileName: string }) {
  const fileExt = fileName.split(".").pop();
  return (
    <img
      src={`/fileicons/type=${fileExt}.png`}
      alt="File Type"
      style={{ width: "48px", height: "48px" }}
    />
  );
}
