export async function parseNumLinesInFiles(files: File[] | null) {
  if (!files) return 0;
  const reader = new FileReader();

  const getFileContent = (file: File) =>
    new Promise<string>((resolve) => {
      reader.onload = () => {
        if (reader.result) resolve(reader.result as string);
        else resolve("");
      };
      reader.readAsText(file);
    });

  let lineSum = 0;
  for (const file of files) {
    const content = await getFileContent(file);
    const numLines =
      content.split(/\r\n|\r|\n/).filter((line) => line).length - 1; // dropping header
    lineSum += numLines;
  }

  return lineSum;
}
