import React, { useState } from "react";
import PartnerApp from "./PartnerApp";
import PartnerFilter, { ALL_PARTNER } from "../shared/components/PartnerFilter";
import { PartnerFilterQuery } from "../graphql/generated/graphql";

import styles from "./PartnerAppAdminWrapper.module.css";

export default function PartnerAppAdminWrapper({
  togglePortal,
  toggleChatBtr,
}: {
  togglePortal: () => void;
  toggleChatBtr: () => void;
}) {
  const defaultRoute = "/lcfs";
  const [partner, setPartner] = useState(ALL_PARTNER);
  const convertAndSetPartner = (legacyOem: PartnerFilterQuery["oems"][0]) => {
    const converted = {
      id: legacyOem.id,
      shortName: legacyOem.shortName,
      fullName: legacyOem.fullName,
      logoUrl: legacyOem.logoUrl ?? "/logo.png",
    };

    setPartner(converted);
  };

  return (
    <React.Fragment>
      <div className={styles.partnerFilter}>
        <PartnerFilter
          currentPartner={partner}
          setPartner={convertAndSetPartner}
        />
      </div>

      <PartnerApp
        defaultRoute={defaultRoute}
        partner={partner}
        togglePortal={togglePortal}
        toggleChatBtr={toggleChatBtr}
      />
    </React.Fragment>
  );
}
