import { IconButton } from "@mui/material";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ChevronRight } from "@mui/icons-material";
import OptionMenu from "../../shared/components/OptionMenu";
import styles from "./UserActionsMenu.module.css";
import ToastBar from "../../shared/components/ToastBar";
import { NavLink } from "react-router-dom";
import PasswordResetButton from "./PasswordResetButton";

export default function UserActionsMenu() {
  const { logout } = useAuth0();
  const [open, setOpen] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);

  return (
    <div className={styles.container}>
      <IconButton
        data-testid={"user-actions-menu-button"}
        onClick={() => setOpen(!open)}
      >
        <ChevronRight />
      </IconButton>

      {open && (
        <OptionMenu toggleOpen={() => setOpen(false)} className={styles.menu}>
          <PasswordResetButton extraActions={() => setOpen(false)}>
            Change Password
          </PasswordResetButton>
          <NavLink to={"login-activity"} onClick={() => setOpen(!open)}>
            Login Activity
          </NavLink>
          <button data-testid={`logout`} onClick={() => logout()}>
            Log Out
          </button>
        </OptionMenu>
      )}

      <ToastBar
        type="success"
        message="An e-mail has been sent to change your password."
        open={showSuccess}
        onClose={() => setShowSuccess(false)}
        relativePosition={false}
      />

      <ToastBar
        type="error"
        message="Failed to send password reset email. Please contact support."
        open={showFailure}
        onClose={() => setShowFailure(false)}
        relativePosition={false}
      />
    </div>
  );
}
