import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { RegistrationStatusPresenter } from "../presenters/RegistrationStatusPresenter";

export default function RegistrationStatus({
  presenter,
}: {
  presenter: RegistrationStatusPresenter;
}) {
  const { palette } = useTheme();

  const {
    approved,
    rejected,
    submitted,
    pending,
    unsubmittedAll,
    unsubmittedCarb,
    deactivated,
  } = presenter.counts;

  return (
    <Box display="flex" flexDirection="row">
      <Box pr={6}>
        <Typography variant="overline" gutterBottom>
          Submitted
        </Typography>
        {extractFieldFromCounts(submitted)}
      </Box>
      <Box pr={6}>
        <Typography variant="overline" gutterBottom>
          Pending
        </Typography>
        {extractFieldFromCounts(pending)}
      </Box>
      <Box pr={6}>
        <Typography variant="overline" gutterBottom>
          Approved
        </Typography>
        {extractFieldFromCounts(approved, palette.success.main)}
      </Box>
      <Box pr={6}>
        <Typography variant="overline" gutterBottom>
          Rejected
        </Typography>
        {extractFieldFromCounts(rejected, palette.error.main)}
      </Box>
      <Box pr={6}>
        <Typography variant="overline" gutterBottom>
          Unsubmitted (All)
        </Typography>
        {extractFieldFromCounts(unsubmittedAll)}
      </Box>
      <Box pr={6}>
        <Typography variant="overline" gutterBottom>
          Unsubmitted (CARB)
        </Typography>
        {extractFieldFromCounts(unsubmittedCarb)}
      </Box>
      <Box pr={6}>
        <Typography variant="overline" gutterBottom>
          Deactivated
        </Typography>
        {extractFieldFromCounts(deactivated)}
      </Box>
      <Box pr={6}>
        <Typography noWrap={true} variant="overline" gutterBottom>
          Rate of Rejection
        </Typography>
        <Typography variant="h6" gutterBottom>
          {presenter.rateOfRejection}
        </Typography>
      </Box>
    </Box>
  );
}

function extractFieldFromCounts(field: number, color?: string) {
  return (
    <Typography variant="h6" style={{ color }} gutterBottom>
      {field.toLocaleString()}
    </Typography>
  );
}
