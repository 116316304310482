import React from "react";
import Footer from "../../PartnerDashboards/Footer";
import styles from "./NoRoleErrorPage.module.css";
import { ErrorOutline } from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import { createTabTitle } from "../../utils/createTabTitle";

export default function NoRoleErrorPage() {
  const { logout } = useAuth0();

  document.title = createTabTitle("Oops!");

  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.circle}>
          <ErrorOutline />
        </div>
        <div className={styles.title}>Oops!</div>
        <div className={styles.message}>
          It looks like you don’t have access to any organizations. Please
          contact your administrator or email us at support@btr.energy for
          assistance.
        </div>
        <div className={styles.buttonGrp}>
          <a href={"mailto:support@btr.energy"} className={styles.contactBtn}>
            Contact us
          </a>
          <button onClick={() => logout()} className={styles.logoutBtn}>
            Logout
          </button>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}
