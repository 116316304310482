import React, { useEffect, useRef } from "react";
import styles from "./SubNavItems.module.css";
import classNames from "classnames";
import { SubItem } from "./NavItem";
import { NavLink, useLocation } from "react-router-dom";
import { useAnalytics } from "@btr-energy/analytics";

export default function SubNavItems({ items }: { items: SubItem[] }) {
  const itemsRef = useRef<HTMLDivElement>(null);
  const bulletRef = useRef<HTMLDivElement>(null);

  const { pathname } = useLocation();
  const analytics = useAnalytics();

  useEffect(() => {
    const items = itemsRef.current;
    const activeBullet = bulletRef.current;
    if (items && activeBullet) {
      const children = Array.from(items.children);

      const activeChild = children.find((el) =>
        el.className.includes("active")
      );

      if (activeChild) {
        const activeItem = activeChild.children[0] as HTMLElement;

        const isAlreadyInDisplay = activeBullet.style.display === "block";

        activeBullet.classList.remove(styles.animation);
        activeBullet.style.transform = `translate(${activeItem.offsetLeft}px, ${activeItem.offsetTop}px)`;
        activeBullet.style.display = "block";

        if (isAlreadyInDisplay) {
          activeBullet.classList.add(styles.animation);
          activeBullet.addEventListener("animationend", () =>
            activeBullet.classList.remove(styles.animation)
          );
        }
      } else {
        activeBullet.style.display = "none";
      }
    }
  }, [pathname]);

  return (
    <div className={styles.wrapper}>
      <div ref={itemsRef} className={styles.items}>
        {items.map((item, index) => (
          <NavLink
            key={index}
            to={`${item.route}`}
            onClick={() =>
              analytics.track({ name: `User clicked ${item.label}` })
            }
            className={styles.item}
          >
            <div className={styles.bulletPoint} />
            {item.label}
          </NavLink>
        ))}
      </div>
      <div
        ref={bulletRef}
        className={classNames(styles.bulletPoint, styles.active)}
      />
    </div>
  );
}
