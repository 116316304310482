export async function changeAuth0Password(
  userEmail: string | undefined
): Promise<void> {
  if (!userEmail) return;

  const body = JSON.stringify({
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    email: userEmail,
    connection: "Username-Password-Users" /* Auth0 DB Name */,
  });

  const response = await fetch(
    `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: body,
    }
  );

  if (!response.ok) {
    return Promise.reject(
      (await response.json())?.displayMessage || "Something went wrong"
    );
  }
}
