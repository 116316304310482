import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { convertDate } from "../../../../utils/convertDate";
import { SubmittedBatchesPresenter } from "../presenters/SubmittedBatchesPresenter";
import { useQuery } from "@apollo/client";
import LoadingIndicator from "../../../../shared/components/LoadingIndicator";
import ErrorIndicator from "../../../../shared/components/ErrorIndicator";
import { graphql } from "../../../../graphql/generated";
import { YearQuarter } from "../../../../graphql/generated/graphql";

export const SUBMITTED_BATCHES = graphql(`
  query submittedRegBatches {
    submittedBatches {
      id
      submittedBatches {
        id
        approvedCount
        name
        pendingCount
        registrationUploadNumber
        rejectedCount
        submittedAt
        vehicleCount
        yearQuarter {
          year
          quarter
        }
      }
    }
  }
`);

export default function SubmittedBatches({
  timeZone,
  chosenQuarter,
}: {
  timeZone: string;
  chosenQuarter: YearQuarter | null;
}) {
  const { data, error, loading } = useQuery(SUBMITTED_BATCHES);

  if (loading) {
    return LoadingIndicator();
  }

  if (error || data === undefined) {
    return ErrorIndicator();
  }

  const chosenBatches = new SubmittedBatchesPresenter(
    data.submittedBatches.submittedBatches,
    chosenQuarter
  ).batches;

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Filename</TableCell>
            <TableCell>Submitted</TableCell>
            <TableCell>No. of Registrations</TableCell>
            <TableCell>Pending</TableCell>
            <TableCell>Approved</TableCell>
            <TableCell>Rejected</TableCell>
            <TableCell>RU</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {chosenBatches.map((batch) => (
            <TableRow key={batch.id}>
              <TableCell component="th" scope="row">
                {batch.name}
              </TableCell>
              <TableCell>{convertDate(batch.submittedAt, timeZone)}</TableCell>
              <TableCell>{batch.vehicleCount.toLocaleString()}</TableCell>
              <TableCell>{batch.pendingCount.toLocaleString()}</TableCell>
              <TableCell>{batch.approvedCount.toLocaleString()}</TableCell>
              <TableCell>{batch.rejectedCount.toLocaleString()}</TableCell>
              <TableCell>{batch.registrationUploadNumber}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
