import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  ListItemText,
  MenuItem,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import GraphQLUpload from "../../../shared/components/GraphQLUpload";
import { ApolloQueryResult, useMutation } from "@apollo/client";
import {
  getFileTypeLayout,
  getFileTypeName,
} from "../../../utils/getFileTypeName";
import { QuarterOptions } from "../../../App/QuarterOptions";
import ClearableOemFilter from "../../../shared/components/ClearableOemFilter";
import YearQuarterAutocomplete from "../../../shared/components/YearQuarterAutocomplete";
import { graphql } from "../../../graphql/generated";
import {
  AccumulatedChargeQuery,
  FileType,
  Oem,
  YearQuarter,
} from "../../../graphql/generated/graphql";
import styled from "@emotion/styled";

export const UPLOAD_CHARGING_EVENTS = graphql(`
  mutation uploadChargingEvents(
    $oemShortname: String!
    $yearQuarter: YearQuarter!
    $files: [Upload!]!
    $fileType: FileType!
  ) {
    uploadChargingEvents(
      oemShortname: $oemShortname
      yearQuarter: $yearQuarter
      files: $files
      type: $fileType
    ) {
      added
    }
  }
`);

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    whiteSpace: "pre-line",
  },
});

export default function ChargingEventsUpload({
  refetch,
}: {
  refetch: () => Promise<ApolloQueryResult<AccumulatedChargeQuery>>;
}) {
  const [mutationUpload, { loading }] = useMutation(UPLOAD_CHARGING_EVENTS);

  const [chosenOem, setChosenOem] = useState<Oem | null>(null);
  const [chosenQuarter, setChosenQuarter] = useState<YearQuarter>(
    QuarterOptions.fromDate(new Date())
  );
  const [fileType, setFileType] = useState<FileType>(
    FileType.UnfilteredWithVin
  );

  function changeOem(e: any, newValue: Oem | null): void {
    setChosenOem(newValue);
  }

  function changeQuarter(e: any, newValue: YearQuarter): void {
    setChosenQuarter(newValue);
  }

  return (
    <Card>
      <CardHeader title="Upload Charge Data" />
      <CardContent>
        <Box pr={6} fontStyle="italic">
          <Typography variant="caption">
            Only csv and json files accepted
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" py={1}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <ClearableOemFilter
                changeOem={changeOem}
                size={"small"}
                testid={"charge-upload-oem-filter"}
              />
            </Grid>
            <Grid item xs={4}>
              <YearQuarterAutocomplete
                changeQuarter={changeQuarter}
                testId={"upload-quarter-filter-autocomplete"}
                size={"small"}
                disabled={
                  fileType === FileType.UnfilteredWithVin ||
                  fileType === FileType.UnfilteredWithBtrid
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                autoSelect
                data-testid="filetype-selector"
                disableClearable
                value={fileType}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Type" variant="outlined" />
                )}
                onChange={(event, value) => setFileType(value)}
                options={[
                  FileType.Audit,
                  FileType.UnfilteredWithVin,
                  FileType.UnfilteredWithBtrid,
                ]}
                getOptionLabel={(option: FileType) => {
                  return getFileTypeName(option);
                }}
                renderOption={(props, option: FileType) => {
                  return (
                    <CustomWidthTooltip
                      title={getFileTypeLayout(option)}
                      placement="left"
                      arrow
                    >
                      <MenuItem {...props} value={option}>
                        <ListItemText primary={getFileTypeName(option)} />
                      </MenuItem>
                    </CustomWidthTooltip>
                  );
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <GraphQLUpload
          accept={[".json", ".csv"]}
          elementName="Charge Events"
          postUploadVerb={"Parsed"}
          loading={loading}
          upload={async (files: File[]) => {
            if (!chosenOem) return;

            return (
              await mutationUpload({
                variables: {
                  oemShortname: chosenOem.shortName,
                  yearQuarter: chosenQuarter,
                  files: files,
                  fileType: fileType,
                },
              })
            )?.data?.uploadChargingEvents.added;
          }}
          postUpload={refetch}
          testId="charging-events-upload-input"
          requiredFields={[chosenOem?.shortName]}
        />
      </CardContent>
    </Card>
  );
}
