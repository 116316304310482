import { Navigate, Route, Routes } from "react-router-dom";
import RegistrationDashboard from "./tabs/Registration/RegistrationDashboard";
import React from "react";
import Dashboard from "./tabs/ChargeData/Dashboard";
import ChargingStationsComponent from "./tabs/ChargingStations/ChargingStationsComponent";
import AdminHeader from "./AdminHeader";

export default function AdminApp({
  togglePortal,
}: {
  togglePortal: () => void;
}) {
  const defaultRoute = "/registrations";

  return (
    <React.Fragment>
      <AdminHeader togglePortal={togglePortal} />
      <Routes>
        <Route path={"/registrations"} element={<RegistrationDashboard />} />
        <Route path={"/charging"} element={<Dashboard />} />
        <Route
          path={"/charging-stations"}
          element={<ChargingStationsComponent />}
        />
        <Route path="/" element={<Navigate replace to={defaultRoute} />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </React.Fragment>
  );
}
