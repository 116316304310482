import React, { useEffect, useState } from "react";
import styles from "./Notifications.module.css";
import { graphql } from "../../../../graphql/generated";
import LottieLoadingIndicator from "../../../../shared/components/LottieLoadingIndicator";
import ErrorIndicator from "../../../../shared/components/ErrorIndicator";
import { useMutation, useQuery } from "@apollo/client";
import { NavLink } from "react-router-dom";

export const NOTIFICATION_STATUS = graphql(`
  query notificationStatus($notificationName: String!) {
    userNotificationStatus(notificationName: $notificationName)
  }
`);

export const MODIFY_NOTIFICATION = graphql(`
  mutation setNotification($notificationName: String!, $enabled: Boolean!) {
    setUserNotificationStatus(
      notificationName: $notificationName
      enabled: $enabled
    )
  }
`);

const ERROR_EMAIL_NOTIFICATION_KEY = "errorEmails";

export default function Notifications() {
  const { data, loading, error, client } = useQuery(NOTIFICATION_STATUS, {
    variables: {
      notificationName: ERROR_EMAIL_NOTIFICATION_KEY,
    },
  });
  const [setNotification] = useMutation(MODIFY_NOTIFICATION);

  const [errorEmails, setErrorEmails] = useState(false);

  const handleEventToggle = async () => {
    setErrorEmails(!errorEmails);

    await setNotification({
      variables: {
        notificationName: ERROR_EMAIL_NOTIFICATION_KEY,
        enabled: !errorEmails,
      },
    });

    client.cache.evict({
      id: "ROOT_QUERY",
      fieldName: "userNotificationStatus",
      broadcast: false,
    });
  };

  useEffect(() => {
    if (!loading && data) {
      setErrorEmails(data.userNotificationStatus);
    }
  }, [loading, data]);

  if (loading) {
    return LottieLoadingIndicator();
  }

  if (error || !data) {
    return ErrorIndicator();
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Notifications</h2>
      <div>
        <div className={styles.boxHeader}>
          <h3>Errors</h3>
        </div>
        <div className={styles.boxContent}>
          <div className={styles.contentHeader}>
            <h3>Email Daily Error Alert Summary</h3>
            <div className={styles.chip}>For APIv2 Integrations Only</div>
          </div>
          <div className={styles.description}>
            Email me a daily error summary that outlines the previous day's
            collection of integration errors. No email will be sent if the
            previous day was error-free.{" "}
            {process.env.REACT_APP_DASHBOARD_DEPLOY_ALLOWED === "true" && (
              <React.Fragment>
                Note, you can view real-time errors within the{" "}
                <NavLink to="/developer-tools">Developer Tools tab</NavLink> for
                faster viewing.{" "}
              </React.Fragment>
            )}
            <br />
            <br />
            <a href="/email_template.pdf" target="_blank">
              View sample alert
            </a>
          </div>
          <div className={styles.toggleContainer}>
            <label className={styles.switch}>
              <input
                type="checkbox"
                checked={errorEmails}
                onChange={handleEventToggle}
              />
              <div className={styles.slider}>
                <div className={styles.knob} />
              </div>
            </label>
            <div>Enable alerts</div>
          </div>
        </div>
      </div>
    </div>
  );
}
