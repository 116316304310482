import React, { useState } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import RegistrationSubmissionFlow from "./components/RegistrationSubmissionFlow";
import RegistrationUpload from "./components/RegistrationUpload";
import SubmittedBatches from "./components/SubmittedBatches";
import MainContainer from "../../../shared/components/MainContainer";
import { useQuery } from "@apollo/client";
import LoadingIndicator from "../../../shared/components/LoadingIndicator";
import ErrorIndicator from "../../../shared/components/ErrorIndicator";
import RegistrationStatus from "./components/RegistrationStatus";
import { RegistrationStatusPresenter } from "./presenters/RegistrationStatusPresenter";
import OemFilter, { ALL_OEM } from "../../../shared/components/OemFilter";
import BetterCardHeader from "../../../shared/components/BetterCardHeader";
import ClearableYearQuarterAutocomplete from "../../../shared/components/ClearableYearQuarterAutocomplete";
import DeregistrationUpload from "./components/DeregistrationUpload";
import { graphql } from "../../../graphql/generated";
import { Oem, YearQuarter } from "../../../graphql/generated/graphql";

export const REGISTRATIONS_DASHBOARD = graphql(`
  query registrationDashboard {
    allOemRegistrationsByQuarter {
      oem {
        id
      }
      approved
      pending
      rejected
      unsubmittedAll
      unsubmittedCarb
      submitted
      deactivated
      yearQuarter {
        year
        quarter
      }
    }
    submissionFlow {
      id
      step
    }
    unsubmittedBatches {
      id
      name
      vehicleCount
    }
    unsubmittedCarbRegistrationsPerOem {
      oem {
        id
        shortName
      }
      unsubmittedCarb
    }
  }
`);

export default function RegistrationDashboard({
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone,
}: {
  timeZone?: string;
}) {
  const [chosenOem, setChosenOem] = useState<Oem>(ALL_OEM);
  const [chosenStatusQuarter, setChosenStatusQuarter] =
    useState<YearQuarter | null>(null);
  const [chosenBatchesQuarter, setChosenBatchesQuarter] =
    useState<YearQuarter | null>(null);

  const { data, error, loading, refetch } = useQuery(REGISTRATIONS_DASHBOARD);

  if (loading) {
    return LoadingIndicator();
  }

  if (error || !data) {
    return ErrorIndicator();
  }

  function changeOem(e: any, newValue: Oem): void {
    setChosenOem(newValue);
  }

  function changeStatusQuarter(e: any, newValue: YearQuarter | null): void {
    setChosenStatusQuarter(newValue);
  }

  function changeBatchesQuarter(e: any, newValue: YearQuarter | null): void {
    setChosenBatchesQuarter(newValue);
  }

  return (
    <MainContainer>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <BetterCardHeader>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant={"h5"}>Registration Status</Typography>
                </Grid>
                <Grid item xs={3}>
                  <OemFilter
                    style={{ width: "100%" }}
                    changeOem={changeOem}
                    size={"small"}
                  />
                </Grid>
                <Grid item xs={3}>
                  <ClearableYearQuarterAutocomplete
                    changeQuarter={changeStatusQuarter}
                    size={"small"}
                  />
                </Grid>
              </Grid>
            </BetterCardHeader>
            <CardContent>
              <RegistrationStatus
                presenter={
                  new RegistrationStatusPresenter(
                    data.allOemRegistrationsByQuarter,
                    chosenOem.id,
                    chosenStatusQuarter
                  )
                }
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <RegistrationUpload postUpload={refetch} />
        </Grid>
        <Grid item xs={12}>
          <RegistrationSubmissionFlow
            submissionFlow={data.submissionFlow}
            unsubmittedBatches={data.unsubmittedBatches}
            oemUnsubmitted={data.unsubmittedCarbRegistrationsPerOem}
            refetch={refetch}
          />
        </Grid>
        <Grid item xs={12}>
          <DeregistrationUpload postUpload={refetch} />
        </Grid>
        <Grid item xs={12}>
          <Card>
            <BetterCardHeader>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={9}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant={"h5"}>
                    Submitted Registrations
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <ClearableYearQuarterAutocomplete
                    changeQuarter={changeBatchesQuarter}
                    size={"small"}
                  />
                </Grid>
              </Grid>
            </BetterCardHeader>
            <CardContent style={{ maxHeight: "475px", overflow: "auto" }}>
              <SubmittedBatches
                timeZone={timeZone}
                chosenQuarter={chosenBatchesQuarter}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
