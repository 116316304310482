export async function downloadWithAuth({
  endpoint,
  token,
  filename,
  params,
}: {
  endpoint: string;
  token: string;
  filename?: string;
  params?: { key: string; value: string | number }[];
}): Promise<void> {
  const response = await fetch(
    `${process.env.REACT_APP_API_URI}/${endpoint}${getParameterString(params)}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    return Promise.reject(
      (await response.json())?.displayMessage || "Something went wrong"
    );
  }

  const objectURL = URL.createObjectURL(await response.blob());

  const contentType = response.headers.get("Content-Type");
  const anchor = document.createElement("a");
  anchor.download = filename || backendFilename(response) || "download";
  anchor.href = objectURL;
  anchor.dataset.downloadurl = [contentType, anchor.download, anchor.href].join(
    ":"
  );
  anchor.click();

  URL.revokeObjectURL(objectURL);
}

function backendFilename(response: Response): string | undefined {
  const header = response.headers.get("Content-Disposition");
  return header?.slice(header.indexOf('filename="') + 10, -1);
}

function getParameterString(
  params: { key: string; value: string | number }[] | undefined
): string {
  return params
    ? `?${params.map(({ key, value }) => `${key}=${value}`).join("&")}`
    : "";
}
