/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation uploadChargingEvents(\n    $oemShortname: String!\n    $yearQuarter: YearQuarter!\n    $files: [Upload!]!\n    $fileType: FileType!\n  ) {\n    uploadChargingEvents(\n      oemShortname: $oemShortname\n      yearQuarter: $yearQuarter\n      files: $files\n      type: $fileType\n    ) {\n      added\n    }\n  }\n": types.UploadChargingEventsDocument,
    "\n  query accumulatedCharge($quarters: [YearQuarter!]!) {\n    chargingEventSummaries(quarters: $quarters) {\n      downloadUrl\n      oem {\n        id\n        shortName\n      }\n      updatedAt\n      wattHours\n      yearQuarter {\n        year\n        quarter\n      }\n      canBeRefiltered\n      carbDateForStationsUsedToFilter\n    }\n  }\n": types.AccumulatedChargeDocument,
    "\n  mutation uploadChargingStations($file: Upload!, $carbUpdateDate: String!) {\n    uploadChargingStations(file: $file, carbUpdateDate: $carbUpdateDate) {\n      replaced\n    }\n  }\n": types.UploadChargingStationsDocument,
    "\n  mutation markStationListAsFinal(\n    $chargingStationList: String!\n    $yearQuarter: YearQuarter!\n  ) {\n    markStationListAsFinal(\n      chargingStationList: $chargingStationList\n      yearQuarter: $yearQuarter\n    )\n  }\n": types.MarkStationListAsFinalDocument,
    "\n  query publicChargingStationLists {\n    publicChargingStationLists {\n      count\n      uploadedDate\n      carbUpdateDate\n      carbListIdentifier\n      finalForQuarters\n    }\n  }\n": types.PublicChargingStationListsDocument,
    "\n  query registrationDashboard {\n    allOemRegistrationsByQuarter {\n      oem {\n        id\n      }\n      approved\n      pending\n      rejected\n      unsubmittedAll\n      unsubmittedCarb\n      submitted\n      deactivated\n      yearQuarter {\n        year\n        quarter\n      }\n    }\n    submissionFlow {\n      id\n      step\n    }\n    unsubmittedBatches {\n      id\n      name\n      vehicleCount\n    }\n    unsubmittedCarbRegistrationsPerOem {\n      oem {\n        id\n        shortName\n      }\n      unsubmittedCarb\n    }\n  }\n": types.RegistrationDashboardDocument,
    "\n  mutation confirmRegistrationBatchSubmission(\n    $confirmations: [BatchSubmissionConfirmation!]!\n  ) {\n    confirmRegistrationBatchSubmission(confirmations: $confirmations) {\n      flow {\n        id\n        step\n      }\n      submittedBatches {\n        id\n        submittedBatches {\n          id\n          approvedCount\n          name\n          pendingCount\n          registrationUploadNumber\n          rejectedCount\n          submittedAt\n          vehicleCount\n        }\n      }\n    }\n  }\n": types.ConfirmRegistrationBatchSubmissionDocument,
    "\n  mutation resetUnsubmittedBatches {\n    resetUnsubmittedBatches {\n      flow {\n        id\n        step\n      }\n    }\n  }\n": types.ResetUnsubmittedBatchesDocument,
    "\n  mutation uploadDeregistrations($files: [Upload!]!, $oemShortName: String!) {\n    uploadDeregistrations(files: $files, oemShortName: $oemShortName) {\n      count\n    }\n  }\n": types.UploadDeregistrationsDocument,
    "\n  mutation confirmDownload {\n    markRegistrationBatchesAsDownloaded {\n      flow {\n        id\n        step\n      }\n    }\n  }\n": types.ConfirmDownloadDocument,
    "\n  mutation createBatches($oemIds: [Int!]!) {\n    createRegistrationBatches(oemIds: $oemIds) {\n      flow {\n        id\n        step\n      }\n      registrationsInUnsubmittedBatches\n    }\n  }\n": types.CreateBatchesDocument,
    "\n  mutation uploadRegistrations(\n    $yearQuarter: YearQuarter!\n    $files: [Upload!]!\n    $type: RegistrationType!\n    $oemShortName: String!\n  ) {\n    uploadRegistrations(\n      yearQuarter: $yearQuarter\n      files: $files\n      type: $type\n      oemShortName: $oemShortName\n    ) {\n      added\n    }\n  }\n": types.UploadRegistrationsDocument,
    "\n  query submittedRegBatches {\n    submittedBatches {\n      id\n      submittedBatches {\n        id\n        approvedCount\n        name\n        pendingCount\n        registrationUploadNumber\n        rejectedCount\n        submittedAt\n        vehicleCount\n        yearQuarter {\n          year\n          quarter\n        }\n      }\n    }\n  }\n": types.SubmittedRegBatchesDocument,
    "\n  query getUserRole {\n    roleOfCurrentUser\n  }\n": types.GetUserRoleDocument,
    "\n  query initialContext {\n    initialContext\n  }\n": types.InitialContextDocument,
    "\n  mutation chatBtrGenerateQuery(\n    $prompt: String\n    $messages: [ChatBTRMessageInput!]!\n  ) {\n    chatBtrGenerateQuery(prompt: $prompt, messages: $messages) {\n      sender\n      type\n      content\n      initialContent\n      initialSql\n    }\n  }\n": types.ChatBtrGenerateQueryDocument,
    "\n  mutation executeSqlStatement($query: String!) {\n    executeSqlStatement(query: $query) {\n      sender\n      type\n      content\n      initialContent\n      initialSql\n    }\n  }\n": types.ExecuteSqlStatementDocument,
    "\n  mutation CancelSqlStatement($query: String!) {\n    cancelSqlStatement(query: $query)\n  }\n": types.CancelSqlStatementDocument,
    "\n  query userPartner {\n    currentUserPartner {\n      id\n      logoUrl\n      fullName\n      shortName\n    }\n  }\n": types.UserPartnerDocument,
    "\n  query checkForLocationData($oemShortname: String!, $location: String!) {\n    partnerHasGivenLocationData(\n      oemShortname: $oemShortname\n      location: $location\n    )\n  }\n": types.CheckForLocationDataDocument,
    "\n  query dashboardQueries($dashboardSlug: String!, $partnerId: Int!) {\n    dashboardConfiguration(dashboardSlug: $dashboardSlug) {\n      id\n      embedUuid\n    }\n    guestToken(dashboardSlug: $dashboardSlug, partnerId: $partnerId)\n    currentUserCanDeploySuperset\n  }\n": types.DashboardQueriesDocument,
    "\n  mutation deployDashboard($dashboardSlug: String!) {\n    deployDashboard(dashboardSlug: $dashboardSlug)\n  }\n": types.DeployDashboardDocument,
    "\n  mutation inviteUser(\n    $oemShortname: String!\n    $userEmail: String!\n    $role: PartnerUserRole!\n  ) {\n    inviteUser(oemShortname: $oemShortname, userEmail: $userEmail, role: $role)\n  }\n": types.InviteUserDocument,
    "\n  query oemInvitedUserList($oemShortname: String!) {\n    invitedUserList(oemShortname: $oemShortname) {\n      id\n      invitee {\n        email\n      }\n      inviter {\n        name\n      }\n      createdAt\n      expiresAt\n      inviteRole\n    }\n  }\n": types.OemInvitedUserListDocument,
    "\n  mutation deleteInvitation($oemShortname: String!, $invitationId: String!) {\n    deletePendingInvitation(\n      oemShortname: $oemShortname\n      invitationId: $invitationId\n    )\n  }\n": types.DeleteInvitationDocument,
    "\n  query oemUserList($oemShortname: String!) {\n    userList(oemShortname: $oemShortname) {\n      email\n      name\n      id\n      picture\n      role\n    }\n  }\n": types.OemUserListDocument,
    "\n  mutation deleteUser($oemShortname: String!, $userId: String!) {\n    deleteUserFromTenant(oemShortname: $oemShortname, userId: $userId)\n  }\n": types.DeleteUserDocument,
    "\n  mutation setPartnerUserRole(\n    $oemShortname: String!\n    $userId: String!\n    $role: PartnerUserRole!\n  ) {\n    setPartnerUserRole(\n      oemShortname: $oemShortname\n      userId: $userId\n      role: $role\n    )\n  }\n": types.SetPartnerUserRoleDocument,
    "\n  query userLoginHistory {\n    userLoginHistory {\n      date\n      ip\n      userAgent\n      country\n      city\n    }\n  }\n": types.UserLoginHistoryDocument,
    "\n  query notificationStatus($notificationName: String!) {\n    userNotificationStatus(notificationName: $notificationName)\n  }\n": types.NotificationStatusDocument,
    "\n  mutation setNotification($notificationName: String!, $enabled: Boolean!) {\n    setUserNotificationStatus(\n      notificationName: $notificationName\n      enabled: $enabled\n    )\n  }\n": types.SetNotificationDocument,
    "\n  mutation deleteDocument($partnerName: String!, $fileName: String!) {\n    deletePartnerDocument(partnerName: $partnerName, fileName: $fileName)\n  }\n": types.DeleteDocumentDocument,
    "\n  mutation uploadDocument($partnerName: String!, $file: Upload!) {\n    uploadPartnerDocument(partnerName: $partnerName, file: $file)\n  }\n": types.UploadDocumentDocument,
    "\n  query partnerDocuments($oemShortname: String!) {\n    partnerDocuments(oemShortname: $oemShortname) {\n      fileName\n      fileSize\n      dateAdded\n      extension\n    }\n  }\n": types.PartnerDocumentsDocument,
    "\n      mutation uploadWrapperTest(\n        $oemShortName: String!\n        $yearQuarter: YearQuarter!\n        $files: [Upload!]!\n        $type: RegistrationType!\n      ) {\n        uploadRegistrations(\n          oemShortName: $oemShortName\n          yearQuarter: $yearQuarter\n          files: $files\n          type: $type\n        ) {\n          added\n        }\n      }\n    ": types.UploadWrapperTestDocument,
    "\n  query oemsFilter {\n    oems {\n      id\n      shortName\n      isGeolocated\n      fullName\n      fein\n    }\n  }\n": types.OemsFilterDocument,
    "\n  query partnerFilter {\n    oems {\n      id\n      shortName\n      logoUrl\n      fullName\n    }\n  }\n": types.PartnerFilterDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation uploadChargingEvents(\n    $oemShortname: String!\n    $yearQuarter: YearQuarter!\n    $files: [Upload!]!\n    $fileType: FileType!\n  ) {\n    uploadChargingEvents(\n      oemShortname: $oemShortname\n      yearQuarter: $yearQuarter\n      files: $files\n      type: $fileType\n    ) {\n      added\n    }\n  }\n"): (typeof documents)["\n  mutation uploadChargingEvents(\n    $oemShortname: String!\n    $yearQuarter: YearQuarter!\n    $files: [Upload!]!\n    $fileType: FileType!\n  ) {\n    uploadChargingEvents(\n      oemShortname: $oemShortname\n      yearQuarter: $yearQuarter\n      files: $files\n      type: $fileType\n    ) {\n      added\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query accumulatedCharge($quarters: [YearQuarter!]!) {\n    chargingEventSummaries(quarters: $quarters) {\n      downloadUrl\n      oem {\n        id\n        shortName\n      }\n      updatedAt\n      wattHours\n      yearQuarter {\n        year\n        quarter\n      }\n      canBeRefiltered\n      carbDateForStationsUsedToFilter\n    }\n  }\n"): (typeof documents)["\n  query accumulatedCharge($quarters: [YearQuarter!]!) {\n    chargingEventSummaries(quarters: $quarters) {\n      downloadUrl\n      oem {\n        id\n        shortName\n      }\n      updatedAt\n      wattHours\n      yearQuarter {\n        year\n        quarter\n      }\n      canBeRefiltered\n      carbDateForStationsUsedToFilter\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation uploadChargingStations($file: Upload!, $carbUpdateDate: String!) {\n    uploadChargingStations(file: $file, carbUpdateDate: $carbUpdateDate) {\n      replaced\n    }\n  }\n"): (typeof documents)["\n  mutation uploadChargingStations($file: Upload!, $carbUpdateDate: String!) {\n    uploadChargingStations(file: $file, carbUpdateDate: $carbUpdateDate) {\n      replaced\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation markStationListAsFinal(\n    $chargingStationList: String!\n    $yearQuarter: YearQuarter!\n  ) {\n    markStationListAsFinal(\n      chargingStationList: $chargingStationList\n      yearQuarter: $yearQuarter\n    )\n  }\n"): (typeof documents)["\n  mutation markStationListAsFinal(\n    $chargingStationList: String!\n    $yearQuarter: YearQuarter!\n  ) {\n    markStationListAsFinal(\n      chargingStationList: $chargingStationList\n      yearQuarter: $yearQuarter\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query publicChargingStationLists {\n    publicChargingStationLists {\n      count\n      uploadedDate\n      carbUpdateDate\n      carbListIdentifier\n      finalForQuarters\n    }\n  }\n"): (typeof documents)["\n  query publicChargingStationLists {\n    publicChargingStationLists {\n      count\n      uploadedDate\n      carbUpdateDate\n      carbListIdentifier\n      finalForQuarters\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query registrationDashboard {\n    allOemRegistrationsByQuarter {\n      oem {\n        id\n      }\n      approved\n      pending\n      rejected\n      unsubmittedAll\n      unsubmittedCarb\n      submitted\n      deactivated\n      yearQuarter {\n        year\n        quarter\n      }\n    }\n    submissionFlow {\n      id\n      step\n    }\n    unsubmittedBatches {\n      id\n      name\n      vehicleCount\n    }\n    unsubmittedCarbRegistrationsPerOem {\n      oem {\n        id\n        shortName\n      }\n      unsubmittedCarb\n    }\n  }\n"): (typeof documents)["\n  query registrationDashboard {\n    allOemRegistrationsByQuarter {\n      oem {\n        id\n      }\n      approved\n      pending\n      rejected\n      unsubmittedAll\n      unsubmittedCarb\n      submitted\n      deactivated\n      yearQuarter {\n        year\n        quarter\n      }\n    }\n    submissionFlow {\n      id\n      step\n    }\n    unsubmittedBatches {\n      id\n      name\n      vehicleCount\n    }\n    unsubmittedCarbRegistrationsPerOem {\n      oem {\n        id\n        shortName\n      }\n      unsubmittedCarb\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation confirmRegistrationBatchSubmission(\n    $confirmations: [BatchSubmissionConfirmation!]!\n  ) {\n    confirmRegistrationBatchSubmission(confirmations: $confirmations) {\n      flow {\n        id\n        step\n      }\n      submittedBatches {\n        id\n        submittedBatches {\n          id\n          approvedCount\n          name\n          pendingCount\n          registrationUploadNumber\n          rejectedCount\n          submittedAt\n          vehicleCount\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation confirmRegistrationBatchSubmission(\n    $confirmations: [BatchSubmissionConfirmation!]!\n  ) {\n    confirmRegistrationBatchSubmission(confirmations: $confirmations) {\n      flow {\n        id\n        step\n      }\n      submittedBatches {\n        id\n        submittedBatches {\n          id\n          approvedCount\n          name\n          pendingCount\n          registrationUploadNumber\n          rejectedCount\n          submittedAt\n          vehicleCount\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation resetUnsubmittedBatches {\n    resetUnsubmittedBatches {\n      flow {\n        id\n        step\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation resetUnsubmittedBatches {\n    resetUnsubmittedBatches {\n      flow {\n        id\n        step\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation uploadDeregistrations($files: [Upload!]!, $oemShortName: String!) {\n    uploadDeregistrations(files: $files, oemShortName: $oemShortName) {\n      count\n    }\n  }\n"): (typeof documents)["\n  mutation uploadDeregistrations($files: [Upload!]!, $oemShortName: String!) {\n    uploadDeregistrations(files: $files, oemShortName: $oemShortName) {\n      count\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation confirmDownload {\n    markRegistrationBatchesAsDownloaded {\n      flow {\n        id\n        step\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation confirmDownload {\n    markRegistrationBatchesAsDownloaded {\n      flow {\n        id\n        step\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createBatches($oemIds: [Int!]!) {\n    createRegistrationBatches(oemIds: $oemIds) {\n      flow {\n        id\n        step\n      }\n      registrationsInUnsubmittedBatches\n    }\n  }\n"): (typeof documents)["\n  mutation createBatches($oemIds: [Int!]!) {\n    createRegistrationBatches(oemIds: $oemIds) {\n      flow {\n        id\n        step\n      }\n      registrationsInUnsubmittedBatches\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation uploadRegistrations(\n    $yearQuarter: YearQuarter!\n    $files: [Upload!]!\n    $type: RegistrationType!\n    $oemShortName: String!\n  ) {\n    uploadRegistrations(\n      yearQuarter: $yearQuarter\n      files: $files\n      type: $type\n      oemShortName: $oemShortName\n    ) {\n      added\n    }\n  }\n"): (typeof documents)["\n  mutation uploadRegistrations(\n    $yearQuarter: YearQuarter!\n    $files: [Upload!]!\n    $type: RegistrationType!\n    $oemShortName: String!\n  ) {\n    uploadRegistrations(\n      yearQuarter: $yearQuarter\n      files: $files\n      type: $type\n      oemShortName: $oemShortName\n    ) {\n      added\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query submittedRegBatches {\n    submittedBatches {\n      id\n      submittedBatches {\n        id\n        approvedCount\n        name\n        pendingCount\n        registrationUploadNumber\n        rejectedCount\n        submittedAt\n        vehicleCount\n        yearQuarter {\n          year\n          quarter\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query submittedRegBatches {\n    submittedBatches {\n      id\n      submittedBatches {\n        id\n        approvedCount\n        name\n        pendingCount\n        registrationUploadNumber\n        rejectedCount\n        submittedAt\n        vehicleCount\n        yearQuarter {\n          year\n          quarter\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getUserRole {\n    roleOfCurrentUser\n  }\n"): (typeof documents)["\n  query getUserRole {\n    roleOfCurrentUser\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query initialContext {\n    initialContext\n  }\n"): (typeof documents)["\n  query initialContext {\n    initialContext\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation chatBtrGenerateQuery(\n    $prompt: String\n    $messages: [ChatBTRMessageInput!]!\n  ) {\n    chatBtrGenerateQuery(prompt: $prompt, messages: $messages) {\n      sender\n      type\n      content\n      initialContent\n      initialSql\n    }\n  }\n"): (typeof documents)["\n  mutation chatBtrGenerateQuery(\n    $prompt: String\n    $messages: [ChatBTRMessageInput!]!\n  ) {\n    chatBtrGenerateQuery(prompt: $prompt, messages: $messages) {\n      sender\n      type\n      content\n      initialContent\n      initialSql\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation executeSqlStatement($query: String!) {\n    executeSqlStatement(query: $query) {\n      sender\n      type\n      content\n      initialContent\n      initialSql\n    }\n  }\n"): (typeof documents)["\n  mutation executeSqlStatement($query: String!) {\n    executeSqlStatement(query: $query) {\n      sender\n      type\n      content\n      initialContent\n      initialSql\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CancelSqlStatement($query: String!) {\n    cancelSqlStatement(query: $query)\n  }\n"): (typeof documents)["\n  mutation CancelSqlStatement($query: String!) {\n    cancelSqlStatement(query: $query)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query userPartner {\n    currentUserPartner {\n      id\n      logoUrl\n      fullName\n      shortName\n    }\n  }\n"): (typeof documents)["\n  query userPartner {\n    currentUserPartner {\n      id\n      logoUrl\n      fullName\n      shortName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query checkForLocationData($oemShortname: String!, $location: String!) {\n    partnerHasGivenLocationData(\n      oemShortname: $oemShortname\n      location: $location\n    )\n  }\n"): (typeof documents)["\n  query checkForLocationData($oemShortname: String!, $location: String!) {\n    partnerHasGivenLocationData(\n      oemShortname: $oemShortname\n      location: $location\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query dashboardQueries($dashboardSlug: String!, $partnerId: Int!) {\n    dashboardConfiguration(dashboardSlug: $dashboardSlug) {\n      id\n      embedUuid\n    }\n    guestToken(dashboardSlug: $dashboardSlug, partnerId: $partnerId)\n    currentUserCanDeploySuperset\n  }\n"): (typeof documents)["\n  query dashboardQueries($dashboardSlug: String!, $partnerId: Int!) {\n    dashboardConfiguration(dashboardSlug: $dashboardSlug) {\n      id\n      embedUuid\n    }\n    guestToken(dashboardSlug: $dashboardSlug, partnerId: $partnerId)\n    currentUserCanDeploySuperset\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deployDashboard($dashboardSlug: String!) {\n    deployDashboard(dashboardSlug: $dashboardSlug)\n  }\n"): (typeof documents)["\n  mutation deployDashboard($dashboardSlug: String!) {\n    deployDashboard(dashboardSlug: $dashboardSlug)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation inviteUser(\n    $oemShortname: String!\n    $userEmail: String!\n    $role: PartnerUserRole!\n  ) {\n    inviteUser(oemShortname: $oemShortname, userEmail: $userEmail, role: $role)\n  }\n"): (typeof documents)["\n  mutation inviteUser(\n    $oemShortname: String!\n    $userEmail: String!\n    $role: PartnerUserRole!\n  ) {\n    inviteUser(oemShortname: $oemShortname, userEmail: $userEmail, role: $role)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query oemInvitedUserList($oemShortname: String!) {\n    invitedUserList(oemShortname: $oemShortname) {\n      id\n      invitee {\n        email\n      }\n      inviter {\n        name\n      }\n      createdAt\n      expiresAt\n      inviteRole\n    }\n  }\n"): (typeof documents)["\n  query oemInvitedUserList($oemShortname: String!) {\n    invitedUserList(oemShortname: $oemShortname) {\n      id\n      invitee {\n        email\n      }\n      inviter {\n        name\n      }\n      createdAt\n      expiresAt\n      inviteRole\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteInvitation($oemShortname: String!, $invitationId: String!) {\n    deletePendingInvitation(\n      oemShortname: $oemShortname\n      invitationId: $invitationId\n    )\n  }\n"): (typeof documents)["\n  mutation deleteInvitation($oemShortname: String!, $invitationId: String!) {\n    deletePendingInvitation(\n      oemShortname: $oemShortname\n      invitationId: $invitationId\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query oemUserList($oemShortname: String!) {\n    userList(oemShortname: $oemShortname) {\n      email\n      name\n      id\n      picture\n      role\n    }\n  }\n"): (typeof documents)["\n  query oemUserList($oemShortname: String!) {\n    userList(oemShortname: $oemShortname) {\n      email\n      name\n      id\n      picture\n      role\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteUser($oemShortname: String!, $userId: String!) {\n    deleteUserFromTenant(oemShortname: $oemShortname, userId: $userId)\n  }\n"): (typeof documents)["\n  mutation deleteUser($oemShortname: String!, $userId: String!) {\n    deleteUserFromTenant(oemShortname: $oemShortname, userId: $userId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation setPartnerUserRole(\n    $oemShortname: String!\n    $userId: String!\n    $role: PartnerUserRole!\n  ) {\n    setPartnerUserRole(\n      oemShortname: $oemShortname\n      userId: $userId\n      role: $role\n    )\n  }\n"): (typeof documents)["\n  mutation setPartnerUserRole(\n    $oemShortname: String!\n    $userId: String!\n    $role: PartnerUserRole!\n  ) {\n    setPartnerUserRole(\n      oemShortname: $oemShortname\n      userId: $userId\n      role: $role\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query userLoginHistory {\n    userLoginHistory {\n      date\n      ip\n      userAgent\n      country\n      city\n    }\n  }\n"): (typeof documents)["\n  query userLoginHistory {\n    userLoginHistory {\n      date\n      ip\n      userAgent\n      country\n      city\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query notificationStatus($notificationName: String!) {\n    userNotificationStatus(notificationName: $notificationName)\n  }\n"): (typeof documents)["\n  query notificationStatus($notificationName: String!) {\n    userNotificationStatus(notificationName: $notificationName)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation setNotification($notificationName: String!, $enabled: Boolean!) {\n    setUserNotificationStatus(\n      notificationName: $notificationName\n      enabled: $enabled\n    )\n  }\n"): (typeof documents)["\n  mutation setNotification($notificationName: String!, $enabled: Boolean!) {\n    setUserNotificationStatus(\n      notificationName: $notificationName\n      enabled: $enabled\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteDocument($partnerName: String!, $fileName: String!) {\n    deletePartnerDocument(partnerName: $partnerName, fileName: $fileName)\n  }\n"): (typeof documents)["\n  mutation deleteDocument($partnerName: String!, $fileName: String!) {\n    deletePartnerDocument(partnerName: $partnerName, fileName: $fileName)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation uploadDocument($partnerName: String!, $file: Upload!) {\n    uploadPartnerDocument(partnerName: $partnerName, file: $file)\n  }\n"): (typeof documents)["\n  mutation uploadDocument($partnerName: String!, $file: Upload!) {\n    uploadPartnerDocument(partnerName: $partnerName, file: $file)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query partnerDocuments($oemShortname: String!) {\n    partnerDocuments(oemShortname: $oemShortname) {\n      fileName\n      fileSize\n      dateAdded\n      extension\n    }\n  }\n"): (typeof documents)["\n  query partnerDocuments($oemShortname: String!) {\n    partnerDocuments(oemShortname: $oemShortname) {\n      fileName\n      fileSize\n      dateAdded\n      extension\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation uploadWrapperTest(\n        $oemShortName: String!\n        $yearQuarter: YearQuarter!\n        $files: [Upload!]!\n        $type: RegistrationType!\n      ) {\n        uploadRegistrations(\n          oemShortName: $oemShortName\n          yearQuarter: $yearQuarter\n          files: $files\n          type: $type\n        ) {\n          added\n        }\n      }\n    "): (typeof documents)["\n      mutation uploadWrapperTest(\n        $oemShortName: String!\n        $yearQuarter: YearQuarter!\n        $files: [Upload!]!\n        $type: RegistrationType!\n      ) {\n        uploadRegistrations(\n          oemShortName: $oemShortName\n          yearQuarter: $yearQuarter\n          files: $files\n          type: $type\n        ) {\n          added\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query oemsFilter {\n    oems {\n      id\n      shortName\n      isGeolocated\n      fullName\n      fein\n    }\n  }\n"): (typeof documents)["\n  query oemsFilter {\n    oems {\n      id\n      shortName\n      isGeolocated\n      fullName\n      fein\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query partnerFilter {\n    oems {\n      id\n      shortName\n      logoUrl\n      fullName\n    }\n  }\n"): (typeof documents)["\n  query partnerFilter {\n    oems {\n      id\n      shortName\n      logoUrl\n      fullName\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;