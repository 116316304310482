import React, { ReactNode, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { changeAuth0Password } from "../../utils/changeAuth0Password";
import ToastBar from "../../shared/components/ToastBar";
import { useAnalytics } from "@btr-energy/analytics";

export default function PasswordResetButton({
  extraActions = () => {},
  children,
}: {
  extraActions?: () => void;
  children: ReactNode[] | ReactNode;
}) {
  const { user } = useAuth0();
  const analytics = useAnalytics();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);

  const canChangePassword = user?.sub?.startsWith("auth0");

  if (!canChangePassword) return null;

  return (
    <React.Fragment>
      <button
        disabled={!canChangePassword}
        data-testid={`change-password-${user?.sub}`}
        onClick={() => {
          analytics.track({ name: "Password reset" });
          extraActions();
          changeAuth0Password(user?.email).then(
            () => setShowSuccess(true),
            () => setShowFailure(true)
          );
        }}
      >
        {children}
      </button>

      <ToastBar
        type="success"
        message="An e-mail has been sent to change your password."
        open={showSuccess}
        onClose={() => setShowSuccess(false)}
        relativePosition={false}
      />

      <ToastBar
        type="error"
        message="Failed to send password reset email. Please contact support."
        open={showFailure}
        onClose={() => setShowFailure(false)}
        relativePosition={false}
      />
    </React.Fragment>
  );
}
