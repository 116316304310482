import React, { useEffect, useRef, useState } from "react";
import hljs from "highlight.js";
import "../styles/highlightjs.css";
import classNames from "classnames";

import ChatWelcome from "./ChatWelcome";
import Loading from "./Loading";
import Tooltip from "./Tooltip";

import thumbsUp from "../images/icons/thumbsup.svg";
import thumbsDown from "../images/icons/thumbsdown.svg";
import sparkles from "../images/icons/sparkles.svg";
import copy from "../images/icons/copy.svg";
import arrow from "../images/icons/arrow.svg";

import {
  ChatBtrMessage,
  ContentType,
  Sender,
} from "../../graphql/generated/graphql";
import styles from "../styles/MessageList.module.css";
import Button from "./Button";
import { SaveOutlined, TableView } from "@mui/icons-material";
import { useSavedQueries } from "../SavedQueriesContext";
import { useMutation } from "@apollo/client";
import { CANCEL_SQL_STATEMENT } from "../queries";

const MS_BEFORE_SHOWING_CANCEL = 3_000;

interface MessageListProps {
  messages: ChatBtrMessage[];
  loadingMessage: boolean;
  runQuery: (query: string, index: number) => void;
  executingQueryIndex: number;
  updateMessageSql: (newQuery: string, index: number) => void;
}

export default function MessageList({
  messages,
  loadingMessage,
  runQuery,
  executingQueryIndex,
  updateMessageSql,
}: MessageListProps) {
  const messageListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current
        .querySelectorAll("code")
        .forEach((block: HTMLElement) => {
          if (!block.dataset.highlighted) hljs.highlightElement(block);
        });
    }
  }, [messages]);

  return (
    <div className={styles.messageList} ref={messageListRef}>
      {messages.length === 0 && <ChatWelcome />}

      {loadingMessage && (
        <div className={styles.chatLoader}>
          <Loading />
        </div>
      )}

      {messages
        .map((message, index) => (
          <React.Fragment key={index}>
            {index === executingQueryIndex && (
              <div className={styles.chatLoader}>
                <Loading /> <CancelButton query={message.content} />
              </div>
            )}

            <div
              className={classNames(styles.message, {
                [styles.user]: message.sender === Sender.User,
                [styles.bot]: message.sender === Sender.Chatbtr,
                [styles.codeMessage]: message.type === ContentType.Sql,
                [styles.resultMessage]: message.type === ContentType.SqlResult,
                [styles.errorMessage]: message.type === ContentType.Error,
              })}
            >
              {message.sender === Sender.Chatbtr && (
                <>
                  {message.type === ContentType.Sql && (
                    <QueryMessage
                      message={message}
                      index={index}
                      runQuery={runQuery}
                      isQueryRunning={executingQueryIndex !== -1}
                      updateMessageSql={updateMessageSql}
                    />
                  )}

                  {message.type === ContentType.Error && (
                    <ErrorMessage message={message} />
                  )}

                  {message.type === ContentType.SqlResult && (
                    <ResultsMessage message={message} />
                  )}
                </>
              )}

              {message.sender === Sender.User && <div>{message.content}</div>}
            </div>
          </React.Fragment>
        ))
        .reverse()}
    </div>
  );
}

function CancelButton({ query }: { query: string }) {
  const [cancelSqlStatement] = useMutation(CANCEL_SQL_STATEMENT);
  const [isCancelling, setIsCancelling] = useState(false);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const timer = setTimeout(
      () => setShowButton(true),
      MS_BEFORE_SHOWING_CANCEL
    );

    return () => clearTimeout(timer);
  }, []);

  const handleCancel = () => {
    setIsCancelling(true);
    void cancelSqlStatement({ variables: { query } });
  };

  if (!showButton) {
    return null;
  }

  return (
    <Button onClick={handleCancel} variant="warn" disabled={isCancelling}>
      {isCancelling ? <Loading /> : <>Cancel Query</>}
    </Button>
  );
}

interface QueryMessageProps {
  message: ChatBtrMessage;
  index: number;
  runQuery: (query: string, index: number) => void;
  isQueryRunning: boolean;
  updateMessageSql: (newQuery: string, index: number) => void;
}

function QueryMessage({
  message,
  index,
  runQuery,
  isQueryRunning,
  updateMessageSql,
}: QueryMessageProps) {
  const codeRef = useRef<HTMLDivElement>(null);
  const [messageHasEdits, setMessageHasEdits] = useState(false);
  const { saveQuery } = useSavedQueries();

  const handleSave = () => {
    if (!codeRef.current?.textContent) return;

    delete codeRef.current.dataset.highlighted;
    updateMessageSql(codeRef.current.textContent, index);

    setMessageHasEdits(false);
  };

  const handleCancel = () => {
    if (!codeRef.current) return;

    codeRef.current.textContent = message.content;

    delete codeRef.current.dataset.highlighted;
    hljs.highlightElement(codeRef.current as HTMLElement);
    handleEdits();
  };

  const handleEdits = () => {
    if (!codeRef.current) return;

    setMessageHasEdits(codeRef.current.textContent !== message.content);
  };

  const messageWasEdited =
    message.initialSql && message.initialSql !== message.content;

  const storeQuery = () => {
    const query = {
      date: new Date().getTime(),
      name: "Unnamed query",
      query: message.content,
    };
    saveQuery(query);
  };

  return (
    <div>
      <span>
        Below is your SQL query. Tap the query box to make any changes.
      </span>

      <div className={styles.codeBlock}>
        <div className={styles.codeHeader}>
          <div className={styles.subtext}>
            <img src={sparkles} alt="Sparkles" /> AI SQL Query
          </div>

          <div className={styles.editAndCopy}>
            {messageWasEdited && (
              <Tooltip
                content={
                  <div>
                    Initial Query: <br />
                    <pre>
                      <code>{message.initialSql}</code>
                    </pre>
                  </div>
                }
              >
                <span className={styles.editedLabel}>Edited</span>
              </Tooltip>
            )}

            <Tooltip content="Copied!" disableHoverListener={true}>
              <button
                onClick={() => navigator.clipboard.writeText(message.content)}
                className={styles.copyCode}
              >
                <img src={copy} alt="Copy code" />
                Copy Code
              </button>
            </Tooltip>
          </div>
        </div>

        <pre className={styles.pre}>
          <code
            ref={codeRef}
            contentEditable={!isQueryRunning}
            suppressContentEditableWarning={true}
            onInput={handleEdits}
          >
            {message.content}
          </code>
        </pre>
      </div>

      <div className={styles.actions}>
        <div className={styles.feedback}>
          <Tooltip content="Good Response">
            <button className={styles.feedbackButton}>
              <img src={thumbsUp} alt="Thumbs up" />
            </button>
          </Tooltip>

          <Tooltip content="Bad Response">
            <button className={styles.feedbackButton}>
              <img src={thumbsDown} alt="Thumbs down" />
            </button>
          </Tooltip>
        </div>

        <div className={styles.queryActionButtons}>
          <Button
            onClick={handleSave}
            className={messageHasEdits ? undefined : styles.hidden}
          >
            Save Edits
          </Button>

          <Button
            variant="warn"
            onClick={handleCancel}
            className={messageHasEdits ? undefined : styles.hidden}
          >
            Cancel Edits
          </Button>

          <Button onClick={storeQuery} disabled={messageHasEdits}>
            Store Query <SaveOutlined />
          </Button>

          <Button
            onClick={() => runQuery(message.content, index)}
            disabled={isQueryRunning || messageHasEdits}
          >
            Run Query <img src={arrow} alt="Arrow" />
          </Button>
        </div>
      </div>
    </div>
  );
}

function ResultsMessage({ message }: { message: ChatBtrMessage }) {
  const lineLimit = 53; // Includes headers

  const truncateContent = (content: string) => {
    const lines = content.trimEnd().split("\n");
    return lines.length > lineLimit
      ? [...lines.slice(0, lineLimit), lines[lines.length - 1]].join("\n")
      : lines.join("\n");
  };

  const hasResults = message.content.length > 0;
  const isOverLineLimit = message.content.split("\n").length > lineLimit;

  const download = () => {
    const csvContent = `data:text/csv;charset=utf-8,${message.initialContent}`;
    const encodedURI = encodeURI(csvContent);
    window.open(encodedURI);
  };

  const dataInsights = () => {
    console.log("TODO");
  };

  return (
    <div>
      <div className={styles.resultHeader}>
        <span>Query Results:</span>

        <Tooltip
          content={
            <pre>
              <code>{message.initialSql}</code>
            </pre>
          }
        >
          <span className={styles.resultQuery}>View Query</span>
        </Tooltip>
      </div>

      <br />

      <pre className={styles.preFull}>
        <code>
          {hasResults ? truncateContent(message.content) : "No results found."}
        </code>
      </pre>

      {isOverLineLimit && (
        <div className={styles.resultsTooLong}>
          *Results truncated. To see more results, refine your query or download
          as a CSV.
        </div>
      )}

      {hasResults && (
        <div className={styles.resultButtons}>
          <Button onClick={download}>
            Export as CSV <TableView />
          </Button>

          <Button onClick={dataInsights}>
            Data Insights <img src={arrow} alt="Arrow" />
          </Button>
        </div>
      )}
    </div>
  );
}

function ErrorMessage({ message }: { message: ChatBtrMessage }) {
  return <div>An error has occurred: {message.content}</div>;
}
