import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import GraphQLUpload from "../../../../shared/components/GraphQLUpload";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Snackbar,
} from "@mui/material";
import ClearableOemFilter from "../../../../shared/components/ClearableOemFilter";
import { parseNumLinesInFiles } from "../../../../utils/parseNumLinesInFiles";
import { graphql } from "../../../../graphql/generated";
import { Oem } from "../../../../graphql/generated/graphql";

export const UPLOAD_DEREGISTRATIONS = graphql(`
  mutation uploadDeregistrations($files: [Upload!]!, $oemShortName: String!) {
    uploadDeregistrations(files: $files, oemShortName: $oemShortName) {
      count
    }
  }
`);

export default function DeregistrationUpload({
  postUpload,
}: {
  postUpload: () => void;
}) {
  const [mutationUpload, { loading }] = useMutation(UPLOAD_DEREGISTRATIONS);

  const [uploadedFiles, setUploadedFiles] = useState<File[] | null>(null);
  const [uploadedSize, setUploadedSize] = useState(0);

  const [chosenOem, setChosenOem] = useState<Oem | null>(null);

  function changeOem(_: any, newValue: Oem | null): void {
    setChosenOem(newValue);
  }

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [failureMessage, setFailureMessage] = useState("");

  const handleConfirmOpen = async (files: File[]) => {
    setUploadedFiles(files);
    setUploadedSize(await parseNumLinesInFiles(files));
    setConfirmOpen(true);
  };

  const handleConfirmDeregister = async () => {
    if (!uploadedFiles || !chosenOem) return;

    await mutationUpload({
      variables: {
        files: uploadedFiles,
        oemShortName: chosenOem.shortName,
      },
    })
      .then((data) =>
        setSuccessMessage(
          `Deregistered ${data.data?.uploadDeregistrations?.count} registrations`
        )
      )
      .catch((e) => setFailureMessage(e.message));
    handleConfirmClose();
    postUpload();
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    setUploadedFiles(null);
  };

  const handleSnackClose = (_: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setFailureMessage("");
    setSuccessMessage("");
  };

  return (
    <Card>
      <CardHeader title="Upload Vehicle Deregistrations" />
      <CardContent>
        <Box display="flex" flexDirection="row" py={1}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <ClearableOemFilter
                changeOem={changeOem}
                size={"small"}
                testid={"deregistration-oem-filter-selector"}
              />
            </Grid>
          </Grid>
        </Box>

        <Dialog
          open={confirmOpen}
          onClose={handleConfirmClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`Deregister ${chosenOem?.shortName} registrations?`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This will deregister <strong>{uploadedSize}</strong> registrations
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmDeregister}>Confirm</Button>
            <Button onClick={handleConfirmClose}>Cancel</Button>
          </DialogActions>
        </Dialog>

        <GraphQLUpload
          accept={[".csv", ".xlsx"]}
          elementName="Registrations"
          loading={loading}
          upload={async (files: File[]) => handleConfirmOpen(files)}
          requiredFields={[chosenOem?.shortName]}
        />

        <Snackbar
          open={!!successMessage}
          autoHideDuration={6000}
          onClose={handleSnackClose}
        >
          <Alert elevation={6} variant="filled" severity="success">
            {successMessage}
          </Alert>
        </Snackbar>

        <Snackbar
          open={!!failureMessage}
          autoHideDuration={6000}
          onClose={handleSnackClose}
        >
          <Alert elevation={6} variant="filled" severity="error">
            {failureMessage}
          </Alert>
        </Snackbar>
      </CardContent>
    </Card>
  );
}
