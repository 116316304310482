import React from "react";
import { useMutation } from "@apollo/client";
import { graphql } from "../../../../graphql/generated";
import GraphQLUpload from "../../../../shared/components/GraphQLUpload";

export const UPLOAD_PARTNER_DOCUMENT = graphql(`
  mutation uploadDocument($partnerName: String!, $file: Upload!) {
    uploadPartnerDocument(partnerName: $partnerName, file: $file)
  }
`);

export function DocumentUpload({
  partnerName,
  refetchDocuments,
}: {
  partnerName: string;
  refetchDocuments: () => void;
}) {
  const [uploadPartnerDocument, { loading }] = useMutation(
    UPLOAD_PARTNER_DOCUMENT
  );

  const upload = async (files: File[]) => {
    await uploadPartnerDocument({
      variables: {
        partnerName: partnerName,
        file: files[0],
      },
    });
    return 0;
  };

  return (
    <div style={{ marginBottom: "30px" }}>
      <GraphQLUpload
        accept={[
          ".pdf",
          ".csv",
          ".xls",
          ".xlsx",
          ".doc",
          ".docx",
          ".txt",
          "application/pdf",
          "text/csv",
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "text/plain",
        ]}
        elementName="Partner Documents"
        loading={loading}
        successMessage={"Partner Document Uploaded"}
        multiFileUpload={false}
        postUpload={refetchDocuments}
        upload={upload}
      />
    </div>
  );
}
