import React, { ButtonHTMLAttributes } from "react";
import classNames from "classnames";

import styles from "../styles/Button.module.css";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary" | "warn";
}

export default function Button({
  children,
  variant = "primary",
  ...props
}: Props) {
  return (
    <button
      {...props}
      className={classNames(styles.button, styles[variant], props.className)}
    >
      {children}
    </button>
  );
}
