import React from "react";
import { AppBar, Divider } from "@mui/material";
import TitleBar from "./TitleBar";
import Navigation from "./Navigation";

export default function AdminHeader({
  togglePortal,
}: {
  togglePortal: () => void;
}) {
  return (
    <header>
      <AppBar position="static" style={{ flexGrow: 1 }}>
        <TitleBar togglePortal={togglePortal} />
        <Divider style={{ backgroundColor: "lightgray" }} />
        <Navigation />
      </AppBar>
    </header>
  );
}
