import React from "react";
import LottieLoadingIndicator from "../shared/components/LottieLoadingIndicator";
import { PartnerUserRole } from "../graphql/generated/graphql";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import AccessDenied from "../shared/components/AccessDenied";
import WAProgramNoParticipation from "../shared/components/WAProgramNoParticipation";
import { graphql } from "../graphql/generated";
import { useQuery } from "@apollo/client";
import { ALL_PARTNER } from "../shared/components/PartnerFilter";
import { useRoles } from "../App/RolesContext";

export const CHECK_SENT_LOCATION_DATA = graphql(`
  query checkForLocationData($oemShortname: String!, $location: String!) {
    partnerHasGivenLocationData(
      oemShortname: $oemShortname
      location: $location
    )
  }
`);

export default function ProtectedRoutes({
  allowedRoles,
  children,
  necessaryLocation,
  oemShortname,
}: {
  allowedRoles: PartnerUserRole[];
  children: ReactJSXElement;
  necessaryLocation?: string;
  oemShortname?: string;
}) {
  const { data, loading: locationCheckLoading } = useQuery(
    CHECK_SENT_LOCATION_DATA,
    {
      variables: {
        oemShortname: oemShortname || "not given",
        location: necessaryLocation || "not given",
      },
      skip:
        !necessaryLocation ||
        !oemShortname ||
        oemShortname === ALL_PARTNER.shortName,
    }
  );

  const { roles, isLoading: loadingRoles } = useRoles();

  if (loadingRoles || locationCheckLoading) {
    return LottieLoadingIndicator();
  }

  const isAllowedAccess =
    allowedRoles.some((r) => roles.includes(r)) ||
    roles.includes(PartnerUserRole.Internal);

  if (!isAllowedAccess) {
    return <AccessDenied />;
  }

  if (
    oemShortname !== ALL_PARTNER.shortName &&
    necessaryLocation &&
    !data?.partnerHasGivenLocationData
  ) {
    return <WAProgramNoParticipation />;
  }

  return children;
}
