import React from "react";
import { useMutation } from "@apollo/client";
import { graphql } from "../../../../graphql/generated";
import Tooltip from "@mui/material/Tooltip";
import { DeleteOutlined } from "@mui/icons-material";

export const DELETE_PARTNER_DOCUMENT = graphql(`
  mutation deleteDocument($partnerName: String!, $fileName: String!) {
    deletePartnerDocument(partnerName: $partnerName, fileName: $fileName)
  }
`);

export function DeleteDocumentButton({
  fileName,
  partnerName,
  refetchDocuments,
}: {
  fileName: string;
  partnerName: string;
  refetchDocuments: () => void;
}) {
  const [deleteDoc] = useMutation(DELETE_PARTNER_DOCUMENT);

  const handleDelete = async () => {
    await deleteDoc({
      variables: {
        partnerName,
        fileName,
      },
    });

    refetchDocuments();
  };

  return (
    <Tooltip title="Delete Document">
      <button
        data-testid="delete-document-button"
        style={{
          color: "#7d8493",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "1.5px solid var(--color-dashboard-dividers)",
          width: "40px",
          height: "40px",
          borderRadius: "8px",
        }}
        onClick={handleDelete}
      >
        <DeleteOutlined />
      </button>
    </Tooltip>
  );
}
