import React, { useEffect, useState } from "react";
import { createTabTitle } from "../utils/createTabTitle";
import Sidenav from "./components/Sidenav";
import MessageList from "./components/MessageList";
import InputContainer from "./components/InputContainer";

import { ApolloError, useMutation, useQuery } from "@apollo/client";
import {
  ChatBtrMessage,
  ContentType,
  Sender,
} from "../graphql/generated/graphql";
import {
  EXECUTE_QUERY,
  MESSAGE_CHATBTR,
  RETRIEVE_DEFAULT_CONTEXT,
} from "./queries";

import styles from "./ChatBTR.module.css";
import { Query, SavedQueriesProvider } from "./SavedQueriesContext";
import { Modal } from "@mui/material";
import Button from "./components/Button";

export default function ChatBTR({
  toggleChatBtr = undefined,
}: {
  toggleChatBtr?: () => void;
}) {
  document.title = createTabTitle("Chat BTR");

  const [messages, setMessages] = useState<ChatBtrMessage[]>([]);
  const [executingQueryIndex, setExecutingQueryIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [selectedQuery, setSelectedQuery] = useState<Query | null>(null);

  const [messageChatBtr, { loading: chatBtrLoading }] =
    useMutation(MESSAGE_CHATBTR);
  const [executeQuery, { loading: queryExecutionLoading }] =
    useMutation(EXECUTE_QUERY);

  const [context, setContext] = useState("");

  const { data: initialContext, loading: defaultContextLoading } = useQuery(
    RETRIEVE_DEFAULT_CONTEXT
  );

  useEffect(() => {
    if (!defaultContextLoading && initialContext?.initialContext) {
      setContext(initialContext.initialContext);
    }
  }, [defaultContextLoading, initialContext]);

  const handleUpdateContext = (newContext: string) => setContext(newContext);

  const handleSubmit = (value: string) => {
    if (value.trim()) {
      const messagesWithUser = [
        ...messages,
        {
          sender: Sender.User,
          content: value,
          type: ContentType.NaturalLanguage,
        },
      ];

      setMessages(messagesWithUser);

      messageChatBtr({
        variables: { prompt: context, messages: messagesWithUser },
      })
        .then((response) => {
          // Could also pop the last message on if this becomes a problem
          const queryResult = response.data?.chatBtrGenerateQuery || [];
          setMessages(queryResult);
        })
        .catch((error: ApolloError | Error) => {
          const newMessages = [
            ...messagesWithUser,
            {
              sender: Sender.Chatbtr,
              content: error.message,
              type: ContentType.Error,
            },
          ];
          setMessages(newMessages);
        });
    }
  };

  const handleRunQuery = (query: string, index: number) => {
    if (queryExecutionLoading) return;

    setExecutingQueryIndex(index);
    executeQuery({ variables: { query } })
      .then((response) => {
        if (!response.data) {
          throw Error("Call to execute failed.");
        }

        const message = response.data.executeSqlStatement;

        addResultToMessages(index, message);
      })
      .catch((error: ApolloError | Error) => {
        const newMessage: ChatBtrMessage = {
          sender: Sender.Chatbtr,
          content: error.message,
          type: ContentType.Error,
          initialSql: query,
        };

        addResultToMessages(index, newMessage);
      })
      .finally(() => {
        setExecutingQueryIndex(-1);
      });
  };

  const updateMessageSql = (query: string, index: number) => {
    if (messages[index].type !== ContentType.Sql) return;

    setMessages((prevMessages) => {
      const newMessages = [...prevMessages];

      newMessages[index] = {
        ...newMessages[index],
        initialSql: newMessages[index].initialSql || newMessages[index].content,
        content: query,
      };

      return newMessages;
    });
  };

  const addResultToMessages = (index: number, newMessage: ChatBtrMessage) => {
    setMessages((prevMessages) => {
      const newMessages = [...prevMessages];
      newMessages.splice(index + 1, 0, newMessage);
      return newMessages;
    });
  };

  const loadQuery = (query: Query) => {
    if (messages.length === 0) {
      setMessages([
        {
          sender: Sender.Chatbtr,
          content: query.query,
          type: ContentType.Sql,
        },
      ]);
    } else {
      setSelectedQuery(query);
      setOpen(true);
    }
  };

  const confirmLoadQuery = () => {
    if (selectedQuery) {
      setMessages([
        {
          sender: Sender.Chatbtr,
          content: selectedQuery.query,
          type: ContentType.Sql,
        },
      ]);
      setOpen(false);
      setSelectedQuery(null);
    }
  };

  const isInputDisabled =
    chatBtrLoading ||
    queryExecutionLoading ||
    defaultContextLoading ||
    executingQueryIndex !== -1;

  return (
    <SavedQueriesProvider>
      <div className={styles.main}>
        <Sidenav toggleChatBtr={toggleChatBtr} onLoadQuery={loadQuery} />

        <div className={styles.chatBox}>
          <MessageList
            messages={messages}
            loadingMessage={chatBtrLoading}
            runQuery={handleRunQuery}
            executingQueryIndex={executingQueryIndex}
            updateMessageSql={updateMessageSql}
          />

          <InputContainer
            handleSubmit={handleSubmit}
            context={context}
            handleUpdateContext={handleUpdateContext}
            contextCanBeUpdated={messages.length === 0}
            isDisabled={isInputDisabled}
          />
        </div>
      </div>

      <Modal open={open} onClose={() => setOpen(false)}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>Load this query?</div>

          <div>This will replace the current chat.</div>

          <div>
            <pre className={styles.pre}>
              <code>{selectedQuery?.query}</code>
            </pre>
          </div>

          <div className={styles.modalActions}>
            <Button onClick={confirmLoadQuery}>Confirm</Button>

            <Button variant="secondary" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </SavedQueriesProvider>
  );
}
