import React, { ReactNode, useEffect, useRef } from "react";
import styles from "./OptionMenu.module.css";
import classNames from "classnames";
import { ClickAwayListener } from "@mui/material";

export default function OptionMenu({
  children,
  toggleOpen,
  className,
}: {
  children: ReactNode[] | ReactNode;
  toggleOpen: () => void;
  className?: string;
}) {
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (menuRef.current) {
      const windowHeight = window.innerHeight;
      const elementRect = menuRef.current.getBoundingClientRect();
      const isOffScreen = elementRect.bottom > windowHeight;

      menuRef.current.classList.toggle(styles.above, isOffScreen);
      menuRef.current.classList.toggle(styles.visible, true);
    }
  }, [menuRef]);

  useEffect(() => {
    const handleScrollOutside = (event: Event) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        toggleOpen();
      }
    };

    window.addEventListener("focus", toggleOpen);
    window.addEventListener("blur", toggleOpen);
    document.addEventListener("wheel", handleScrollOutside);

    return () => {
      window.removeEventListener("focus", toggleOpen);
      window.removeEventListener("blur", toggleOpen);
      document.removeEventListener("wheel", handleScrollOutside);
    };
  }, [toggleOpen]);

  // Using clickaway listener to get around weird bug where MUI components
  // can interfere with normal document event listeners
  return (
    <ClickAwayListener onClickAway={toggleOpen}>
      <div className={styles.wrapper}>
        <div ref={menuRef} className={classNames(styles.options, className)}>
          {children}
        </div>
      </div>
    </ClickAwayListener>
  );
}
