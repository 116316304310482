import React, { FormEvent, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import styles from "./InviteUser.module.css";
import {
  partnerRoleLabels,
  readableUserRole,
} from "../../../../utils/partnerUserRoles";
import { ExpandMore, MailOutline } from "@mui/icons-material";
import classNames from "classnames";
import OptionMenu from "../../../../shared/components/OptionMenu";
import ToastBar from "../../../../shared/components/ToastBar";
import { PartnerUserRole } from "../../../../graphql/generated/graphql";
import { graphql } from "../../../../graphql/generated";
import LottieLoadingIndicator from "../../../../shared/components/LottieLoadingIndicator";

export const INVITE_USER = graphql(`
  mutation inviteUser(
    $oemShortname: String!
    $userEmail: String!
    $role: PartnerUserRole!
  ) {
    inviteUser(oemShortname: $oemShortname, userEmail: $userEmail, role: $role)
  }
`);

export const OEM_INVITED_USERS = graphql(`
  query oemInvitedUserList($oemShortname: String!) {
    invitedUserList(oemShortname: $oemShortname) {
      id
      invitee {
        email
      }
      inviter {
        name
      }
      createdAt
      expiresAt
      inviteRole
    }
  }
`);

export default function InviteUser({ oemShortname }: { oemShortname: string }) {
  const [inviteNewUser, { loading }] = useMutation(INVITE_USER);
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserRole, setNewUserRole] = useState(PartnerUserRole.User);
  const [openRoleDropdown, setOpenRoleDropdown] = useState(false);

  const [showSuccess, setShowSuccess] = useState(false);
  const [lastSuccess, setLastSuccess] = useState("");
  const [showFailure, setShowFailure] = useState(false);
  const [validEmail, setValidEmail] = useState(true);
  const { refetch } = useQuery(OEM_INVITED_USERS, {
    skip: true,
    variables: { oemShortname: oemShortname },
  });

  const handleInvite = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validEmailString = /\S+@\S+\.\S+/;
    const isValid = validEmailString.test(newUserEmail);
    setValidEmail(isValid);
    if (isValid) {
      const variables = {
        oemShortname: oemShortname,
        userEmail: newUserEmail,
        role: newUserRole,
      };

      try {
        await inviteNewUser({ variables });
      } catch {
        return setShowFailure(true);
      }

      setLastSuccess(newUserEmail);
      setShowSuccess(true);
      setNewUserEmail("");
      await refetch();
    }
  };

  return (
    <div>
      <h2 className={styles.title}>Invite Team Members</h2>
      <form
        id="inviteUser"
        className={styles.inviteForm}
        onSubmit={handleInvite}
      >
        <div className={styles.emailInputWrapper}>
          <input
            required
            type="text"
            value={newUserEmail}
            onChange={(event) => {
              setNewUserEmail(event.target.value);
            }}
            className={classNames(styles.inviteEmail, {
              [styles.invalid]: !validEmail,
            })}
          />
          <span className={styles.emailPlaceholder}>
            <MailOutline /> <span>example@example.com</span>
          </span>
          {!validEmail && (
            <div className={styles.invalidEmail}>Invalid email</div>
          )}
        </div>

        <div className={styles.menuContainer}>
          <button
            className={styles.roleDropdown}
            onClick={() => setOpenRoleDropdown(!openRoleDropdown)}
            type="button"
          >
            {readableUserRole(newUserRole)} <ExpandMore />
          </button>

          {openRoleDropdown && (
            <OptionMenu
              className={styles.roleOptions}
              toggleOpen={() => setOpenRoleDropdown(false)}
            >
              {partnerRoleLabels.map((role) => (
                <button
                  key={role.label}
                  className={classNames({
                    [styles.activeRole]: role.value === newUserRole,
                  })}
                  onClick={() => {
                    setOpenRoleDropdown(false);
                    setNewUserRole(role.value);
                  }}
                  type="button"
                >
                  {role.label}
                </button>
              ))}
            </OptionMenu>
          )}
        </div>

        {loading ? (
          <div className={classNames(styles.submitInvite, styles.loader)}>
            {LottieLoadingIndicator(40)}
          </div>
        ) : (
          <button className={styles.submitInvite} type="submit">
            Invite
          </button>
        )}
      </form>

      <ToastBar
        type="success"
        message={`Invitation has been sent to ${lastSuccess}.`}
        open={showSuccess}
        onClose={() => setShowSuccess(false)}
      />

      <ToastBar
        type="error"
        message="Failed to send invite email. Please contact support."
        open={showFailure}
        onClose={() => setShowFailure(false)}
      />
    </div>
  );
}
