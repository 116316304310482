import React from "react";
import { Grid } from "@mui/material";
import { useQuery } from "@apollo/client";
import MainContainer from "../../../shared/components/MainContainer";
import Submissions from "./Submissions";
import ChargingEventsUpload from "./ChargingEventsUpload";
import { downloadWithAuth } from "../../../utils/downloadWithAuth";
import LoadingIndicator from "../../../shared/components/LoadingIndicator";
import ErrorIndicator from "../../../shared/components/ErrorIndicator";
import { useQuarterOptions } from "../../../App/QuarterOptionsContext";
import { graphql } from "../../../graphql/generated";

export const CHARGING_DASHBOARD = graphql(`
  query accumulatedCharge($quarters: [YearQuarter!]!) {
    chargingEventSummaries(quarters: $quarters) {
      downloadUrl
      oem {
        id
        shortName
      }
      updatedAt
      wattHours
      yearQuarter {
        year
        quarter
      }
      canBeRefiltered
      carbDateForStationsUsedToFilter
    }
  }
`);

export default function Dashboard() {
  const { options: previousFourQuarters } = useQuarterOptions();

  const { error, loading, data, refetch } = useQuery(CHARGING_DASHBOARD, {
    variables: {
      quarters: previousFourQuarters,
    },
  });

  if (loading) {
    return LoadingIndicator();
  }

  if (error || data === undefined) {
    return ErrorIndicator();
  }

  return (
    <MainContainer>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ChargingEventsUpload refetch={refetch} />
        </Grid>
        <Grid item xs={12}>
          <Submissions
            batches={data.chargingEventSummaries}
            downloader={downloadWithAuth}
          />
        </Grid>
      </Grid>
    </MainContainer>
  );
}
