import { graphql } from "../graphql/generated";

export const RETRIEVE_DEFAULT_CONTEXT = graphql(`
  query initialContext {
    initialContext
  }
`);

export const MESSAGE_CHATBTR = graphql(`
  mutation chatBtrGenerateQuery(
    $prompt: String
    $messages: [ChatBTRMessageInput!]!
  ) {
    chatBtrGenerateQuery(prompt: $prompt, messages: $messages) {
      sender
      type
      content
      initialContent
      initialSql
    }
  }
`);

export const EXECUTE_QUERY = graphql(`
  mutation executeSqlStatement($query: String!) {
    executeSqlStatement(query: $query) {
      sender
      type
      content
      initialContent
      initialSql
    }
  }
`);

export const CANCEL_SQL_STATEMENT = graphql(`
  mutation CancelSqlStatement($query: String!) {
    cancelSqlStatement(query: $query)
  }
`);
