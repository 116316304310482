import React from "react";
import styles from "./AccessDenied.module.css";
import { Lock } from "@mui/icons-material";

export default function AccessDenied() {
  return (
    <div className={styles.container}>
      <div className={styles.circle}>
        <Lock />
      </div>
      <div className={styles.title}>Access Denied</div>
      <div className={styles.message}>
        Your account does not currently have permissions to view this data. To
        request access, please contact your administrator or email us at
        support@btr.energy for assistance.
      </div>
      <a href={"mailto:support@btr.energy"} className={styles.contactBtn}>
        Contact us
      </a>
    </div>
  );
}
