import React from "react";
import chatBtrLogo from "../images/chatbtrlogofull.png";
import styles from "../styles/ChatWelcome.module.css";

export default function ChatWelcome() {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={chatBtrLogo} alt="Chat BTR" className={styles.logo} />
        <div>Your AI assistant.</div>
      </div>

      <div className={styles.cards}>
        <div className={styles.card}>
          <QuestionMarkIcon />
          Ask anything you'd like. Data stays with BTR.
        </div>

        <div className={styles.card}>
          <QuestionAnswerIcon />
          Find answer without technical support.
        </div>

        <div className={styles.card}>
          <PencilIcon />
          Provide feedback on response accuracy.
        </div>
      </div>
    </div>
  );
}

function QuestionMarkIcon() {
  return (
    <div className={styles.questionMarkIcon}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
      >
        <path
          d="M11.071 13.6415C11.841 12.2515 13.321 11.4315 14.181 10.2015C15.091 8.9115 14.581 6.5015 12.001 6.5015C10.311 6.5015 9.48102 7.7815 9.13102 8.8415L6.54102 7.7515C7.25102 5.6215 9.18102 3.7915 11.991 3.7915C14.341 3.7915 15.951 4.8615 16.771 6.2015C17.471 7.3515 17.881 9.5015 16.801 11.1015C15.601 12.8715 14.451 13.4115 13.831 14.5515C13.581 15.0115 13.481 15.3115 13.481 16.7915H10.591C10.581 16.0115 10.461 14.7415 11.071 13.6415ZM14.001 20.7915C14.001 21.8915 13.101 22.7915 12.001 22.7915C10.901 22.7915 10.001 21.8915 10.001 20.7915C10.001 19.6915 10.901 18.7915 12.001 18.7915C13.101 18.7915 14.001 19.6915 14.001 20.7915Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

function QuestionAnswerIcon() {
  return (
    <div className={styles.questionAnswerIcon}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
      >
        <path
          d="M5.62102 15.7915L1.16602 19.2915V3.7915C1.16602 3.52629 1.27137 3.27193 1.45891 3.0844C1.64645 2.89686 1.9008 2.7915 2.16602 2.7915H17.166C17.4312 2.7915 17.6856 2.89686 17.8731 3.0844C18.0607 3.27193 18.166 3.52629 18.166 3.7915V15.7915H5.62102ZM4.92902 13.7915H16.166V4.7915H3.16602V15.1765L4.92902 13.7915ZM8.16602 17.7915H18.403L20.166 19.1765V8.7915H21.166C21.4312 8.7915 21.6856 8.89686 21.8731 9.0844C22.0607 9.27193 22.166 9.52629 22.166 9.7915V23.2915L17.712 19.7915H9.16602C8.9008 19.7915 8.64645 19.6861 8.45891 19.4986C8.27137 19.3111 8.16602 19.0567 8.16602 18.7915V17.7915Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

function PencilIcon() {
  return (
    <div className={styles.pencilIcon}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
      >
        <path
          d="M16.0813 3.73841C16.4564 3.36346 16.965 3.15283 17.4953 3.15283C18.0257 3.15283 18.5343 3.36346 18.9093 3.73841L21.3843 6.21341C21.5701 6.39913 21.7175 6.61965 21.8181 6.86235C21.9186 7.10506 21.9704 7.36519 21.9704 7.62791C21.9704 7.89062 21.9186 8.15076 21.8181 8.39346C21.7175 8.63616 21.5701 8.85668 21.3843 9.04241L9.49133 20.9354L3.11133 22.0114L4.18833 15.6314L16.0813 3.73841ZM15.8523 6.79541L18.3273 9.27041L19.9703 7.62741L17.4953 5.15341L15.8523 6.79541ZM16.9123 10.6854L14.4383 8.21041L6.05433 16.5944L5.55133 19.5714L8.52833 19.0694L16.9123 10.6854Z"
          fill="white"
        />
      </svg>
    </div>
  );
}
