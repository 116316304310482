import React, { useEffect, useRef } from "react";
import styles from "./SupersetDashboard.module.css";
import { useQuery } from "@apollo/client";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import LottieLoadingIndicator from "../../shared/components/LottieLoadingIndicator";
import { graphql } from "../../graphql/generated";
import SupersetDeployButton from "./SupersetDeployButton";

export const SUPERSET_DASHBOARD_QUERIES = graphql(`
  query dashboardQueries($dashboardSlug: String!, $partnerId: Int!) {
    dashboardConfiguration(dashboardSlug: $dashboardSlug) {
      id
      embedUuid
    }
    guestToken(dashboardSlug: $dashboardSlug, partnerId: $partnerId)
    currentUserCanDeploySuperset
  }
`);

export default function SupersetDashboard({
  dashboardSlug,
  partnerId,
}: {
  dashboardSlug: string;
  partnerId: number;
}) {
  const { data, loading, refetch } = useQuery(SUPERSET_DASHBOARD_QUERIES, {
    variables: { dashboardSlug: dashboardSlug, partnerId: partnerId },
  });

  const ref = useRef<HTMLDivElement>(null);

  const getUnexpiredToken = async () => {
    const token = data?.guestToken || "";
    const gracePeriodMs = 5000;
    const tokenExpired =
      Date.now() >=
      JSON.parse(atob(token.split(".")[1])).exp * 1000 - gracePeriodMs;

    if (!tokenExpired) return token;

    return refetch().then((result) => {
      return result.data.guestToken;
    });
  };

  useEffect(() => {
    if (!ref.current || !data) return;

    void embedDashboard({
      id: data.dashboardConfiguration.embedUuid,
      supersetDomain: process.env.REACT_APP_SUPERSET_URI || "",
      mountPoint: ref.current,
      fetchGuestToken: () => getUnexpiredToken(),
      dashboardUiConfig: {
        hideTab: true,
        hideTitle: true,
        hideChartControls: false,
        filters: {
          expanded: false,
          visible: false,
        },
      },
    });
  }, [data, dashboardSlug]);

  if (loading || !data) {
    return LottieLoadingIndicator();
  }

  return (
    <React.Fragment>
      <div className={styles.container} ref={ref} />
      <SupersetDeployButton
        dashboardSlug={dashboardSlug}
        canDeployDashboard={data.currentUserCanDeploySuperset}
      />
    </React.Fragment>
  );
}
