import React from "react";
import { StyledEngineProvider } from "@mui/material";
import * as Sentry from "@sentry/react";
import AuthenticatedApolloProvider from "../graphql/AuthenticatedApolloProvider";
import AppBodyWrapper from "./AppBodyWrapper";
import { QuarterOptionsProvider } from "./QuarterOptionsContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { AnalyticsProvider, MixpanelAnalytics } from "@btr-energy/analytics";
import styles from "./App.module.css";
import { RoleProvider } from "./RolesContext";

if (process.env.REACT_APP_SENTRY_URL) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    environment: process.env.REACT_APP_SENTRY_ENV,
    release: process.env.REACT_APP_SENTRY_RELEASE,
  });
}

const analytics = new MixpanelAnalytics({
  token: process.env.REACT_APP_ANALYTICS_MIXPANEL_PROJECT_TOKEN || "",
  apiHost: "https://analytics.btr.energy",
  enableWebVitals: true,
  debug: process.env.NODE_ENV !== "production",
});

// TODO: put this inside of index.tsx once mui and quarter providers can be removed
export default function App() {
  return (
    <QuarterOptionsProvider date={new Date()}>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
        }}
      >
        <RoleProvider>
          <AuthenticatedApolloProvider>
            <AnalyticsProvider value={analytics}>
              <StyledEngineProvider injectFirst>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <BrowserRouter>
                    <div className={styles.root}>
                      <AppBodyWrapper />
                    </div>
                  </BrowserRouter>
                </LocalizationProvider>
              </StyledEngineProvider>
            </AnalyticsProvider>
          </AuthenticatedApolloProvider>
        </RoleProvider>
      </Auth0Provider>
    </QuarterOptionsProvider>
  );
}
