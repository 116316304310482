import { Autocomplete, TextField } from "@mui/material";
import React, { CSSProperties } from "react";
import { useQuery } from "@apollo/client";
import { OEMS_FILTER_QUERY } from "./OemFilter";
import { Oem } from "../../graphql/generated/graphql";

export default function ClearableOemFilter({
  changeOem,
  style,
  size = "medium",
  testid = "oem-filter-selector",
}: {
  changeOem: (e: any, newValue: Oem | null) => void;
  style?: CSSProperties;
  size?: "small" | "medium";
  testid?: string;
}) {
  const { error, data, loading } = useQuery(OEMS_FILTER_QUERY);

  if (loading) {
    return <div />;
  }

  if (error || !data?.oems) {
    return <div />;
  }

  return (
    <Autocomplete
      data-testid={testid}
      autoSelect
      id="oem-select-box"
      options={data.oems}
      size={size}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      defaultValue={null}
      getOptionLabel={(option) => option.shortName}
      style={style}
      renderInput={(params) => (
        <TextField label={"Partner"} {...params} variant="outlined" />
      )}
      onChange={changeOem}
    />
  );
}
