import React from "react";
import { Button, Link, Toolbar } from "@mui/material";
import styles from "./TitleBar.module.css";
import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export default function TitleBar({
  togglePortal,
}: {
  togglePortal: () => void;
}) {
  const { logout } = useAuth0();

  return (
    <Toolbar className={styles.container}>
      <div className={styles.logoControls}>
        <Link href="/" underline="none">
          <img
            src="/logo-full.png"
            alt="BTR Bridge"
            className={styles.logoImage}
          />
        </Link>

        <NavLink to={"/"} onClick={togglePortal} className={styles.portal}>
          Switch to Portal View
        </NavLink>
      </div>

      <div className={styles.userControls}>
        <Button color="inherit" onClick={() => logout()}>
          Log out
        </Button>
      </div>
    </Toolbar>
  );
}
