import { useMemo } from "react";
import classNames from "classnames";
import styles from "./Pagination.module.css";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

export default function Pagination({
  items,
  countPerPage,
  currentPage,
  setPage,
}: {
  items: any[];
  countPerPage: number;
  currentPage: number;
  setPage: (value: number) => void;
}) {
  const totalPageCount = Math.ceil(items.length / countPerPage);

  const paginationRange = useMemo(() => {
    const range = (start: number, end: number) => {
      const length = end - start + 1;
      return Array.from({ length }, (_, idx) => idx + start);
    };

    // total number of pagination boxes
    const totalPageNumbers = 7;

    // # of pages we have is less than the page numbers we want to show
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    // numbers left and right of current
    const leftSiblingIndex = Math.max(currentPage - 1, 1);
    const rightSiblingIndex = Math.min(currentPage + 1, totalPageCount);

    const showLeftDots = leftSiblingIndex > 2;
    const showRightDots = rightSiblingIndex < totalPageCount - 2;

    const lastPageIndex = totalPageCount;

    // right ellipsis only
    if (!showLeftDots && showRightDots) {
      const leftCount = 4;
      const leftRange = range(1, leftCount);

      return [...leftRange, "…", totalPageCount - 1, totalPageCount];
    }

    // left ellipsis only
    if (showLeftDots && !showRightDots) {
      const rightCount = 4;
      const rightRange = range(totalPageCount - rightCount + 1, totalPageCount);

      return [1, 2, "…", ...rightRange];
    }

    // both ellipses
    if (showLeftDots && showRightDots) {
      return [1, 2, "…", currentPage, "…", lastPageIndex - 1, lastPageIndex];
    }

    return [];
  }, [items, currentPage, countPerPage]);

  const onPrevious = () => setPage(currentPage - 1);

  const onNext = () => setPage(currentPage + 1);

  return (
    <ul className={styles.container}>
      <li
        className={classNames(styles.item, {
          [styles.disabled]: currentPage === 1,
        })}
        onClick={onPrevious}
      >
        <ChevronLeft />
      </li>

      {paginationRange.map((range, index) => {
        if (typeof range !== "number") {
          return (
            <li key={index} className={classNames(styles.item, styles.dots)}>
              {range}
            </li>
          );
        }

        return (
          <li
            key={index}
            className={classNames(styles.item, {
              [styles.selected]: range === currentPage,
            })}
            onClick={() => setPage(range)}
          >
            {range}
          </li>
        );
      })}

      <li
        className={classNames(styles.item, {
          [styles.disabled]: currentPage === totalPageCount,
        })}
        onClick={onNext}
      >
        <ChevronRight />
      </li>
    </ul>
  );
}
