import { PartnerUserRole } from "../graphql/generated/graphql";
import { IdToken } from "@auth0/auth0-react";

export const partnerRoleLabels = [
  {
    label: "User",
    value: PartnerUserRole.User,
    auth0Role: "OEM Dashboard Users",
  },
  {
    label: "Developer",
    value: PartnerUserRole.Developer,
    auth0Role: "OEM Dashboard Developers",
  },
  {
    label: "User + Developer",
    value: PartnerUserRole.UserDeveloper,
    auth0Role: "OEM Dashboard User + Developer",
  },
  {
    label: "Admin",
    value: PartnerUserRole.Admin,
    auth0Role: "OEM Dashboard Admins",
  },
];

export const allRoleLabels = [
  ...partnerRoleLabels,
  {
    label: "Internal BTR Admin",
    value: PartnerUserRole.Internal,
    auth0Role: "Internal BTR Admin",
  },
];

export function readableUserRole(role: string | null | undefined): string {
  return (
    allRoleLabels.find(
      (partnerRoles) =>
        role === partnerRoles.auth0Role || role === partnerRoles.value
    )?.label || "No Role"
  );
}

export function translateClaimsToRoles(
  claims: IdToken | undefined
): PartnerUserRole[] {
  if (!claims) {
    return [];
  }

  return (
    claims["https://btr.energy/roles"]?.map(
      (role: string) =>
        allRoleLabels.find((partnerRoles) => role === partnerRoles.auth0Role)
          ?.value || undefined
    ) || []
  );
}
