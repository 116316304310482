import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from "@apollo/client";
import React, { ReactNode } from "react";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "@auth0/auth0-react";

export default function AuthenticatedApolloProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { getAccessTokenSilently } = useAuth0();

  const authLink = setContext(async () => {
    const token = await getAccessTokenSilently();

    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  });

  const client = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.error(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );
        if (networkError) console.error(`[Network error]: ${networkError}`);
      }),
      authLink,
      createUploadLink({
        uri: `${process.env.REACT_APP_API_URI}/graphql`,
      }),
    ]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
