import { NavTab } from "./NavItem";
import CaliforniaIcon from "../shared/logos/CaliforniaIcon";
import { PartnerUserRole } from "../../graphql/generated/graphql";
import WashingtonIcon from "../shared/logos/WashingtonIcon";
import {
  BuildOutlined,
  FileCopyOutlined,
  NotificationsNone,
  PersonOutline,
} from "@mui/icons-material";

export const topNavTabs: NavTab[] = [
  {
    icon: CaliforniaIcon,
    label: "California",
    route: "/lcfs",
    subItems: [
      { label: "Charging Insights", route: "/lcfs/charging-insights" },
      { label: "Market Insights", route: "/lcfs/market-insights" },
    ],
    allowedRoles: [
      PartnerUserRole.Internal,
      PartnerUserRole.Admin,
      PartnerUserRole.User,
      PartnerUserRole.UserDeveloper,
    ],
  },
  {
    icon: WashingtonIcon,
    label: "Washington",
    route: "/washington",
    allowedRoles: [
      PartnerUserRole.Internal,
      PartnerUserRole.Admin,
      PartnerUserRole.User,
      PartnerUserRole.UserDeveloper,
    ],
  },
  {
    icon: BuildOutlined,
    label: "Developer Tools",
    route: "/developer-tools",
    allowedRoles: [
      PartnerUserRole.Internal,
      PartnerUserRole.Admin,
      PartnerUserRole.Developer,
      PartnerUserRole.UserDeveloper,
    ],
  },
];

export const bottomNavTabs: NavTab[] = [
  {
    icon: FileCopyOutlined,
    label: "Resources",
    route: "/resources",
    allowedRoles: [
      PartnerUserRole.Internal,
      PartnerUserRole.Admin,
      PartnerUserRole.User,
      PartnerUserRole.UserDeveloper,
    ],
  },
  {
    icon: NotificationsNone,
    label: "Notifications",
    route: "/notifications",
    allowedRoles: [
      PartnerUserRole.Internal,
      PartnerUserRole.Admin,
      PartnerUserRole.User,
      PartnerUserRole.UserDeveloper,
      PartnerUserRole.Developer,
    ],
  },
  {
    icon: PersonOutline,
    label: "Account Management",
    route: "/accounts",
    allowedRoles: [
      PartnerUserRole.Internal,
      PartnerUserRole.Admin,
      PartnerUserRole.User,
      PartnerUserRole.UserDeveloper,
      PartnerUserRole.Developer,
    ],
  },
];
