import MainContainer from "./MainContainer";
import React from "react";
import { Box, CircularProgress } from "@mui/material";

export default function LoadingIndicator(size = 300) {
  return (
    <MainContainer>
      <Box
        alignContent="center"
        alignItems="center"
        display="flex"
        flexDirection="column"
        height={size}
        justifyContent="space-evenly"
      >
        <CircularProgress />
      </Box>
    </MainContainer>
  );
}
