import React, { useEffect, useRef } from "react";
import styles from "./ToastBar.module.css";
import { CheckCircle, Close, Warning } from "@mui/icons-material";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";

export default function ToastBar({
  type,
  message,
  open,
  onClose,
  relativePosition = true,
}: {
  type: "success" | "error";
  message: string;
  open: boolean;
  onClose: () => void;
  relativePosition?: boolean;
}) {
  const toastRef = useRef(null);

  useEffect(() => {
    if (open) setTimeout(onClose, 3500);
  }, [onClose, open]);

  return (
    <CSSTransition
      nodeRef={toastRef}
      in={open}
      timeout={500}
      mountOnEnter={true}
      unmountOnExit={true}
      classNames={{ ...styles }}
    >
      <div
        ref={toastRef}
        className={classNames(
          styles.toastWrapper,
          relativePosition ? styles.relative : ""
        )}
      >
        <div
          className={classNames(
            styles.toast,
            type === "success" ? styles.success : styles.error
          )}
        >
          {type === "success" ? <CheckCircle /> : <Warning />}

          <div>
            <div className={styles.title}>{type}</div>
            <div className={styles.message}>{message}</div>
          </div>

          <div className={styles.divider} />

          <button onClick={onClose} className={styles.closeBtn}>
            <Close />
          </button>
        </div>
      </div>
    </CSSTransition>
  );
}
